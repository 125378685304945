import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from '@material-ui/core/Button';
import Table from "components/Table/Table.jsx";
import TextField from '@material-ui/core/TextField';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Done from "components/Table/Commandes/Done";
import Scheduled from "components/Table/Commandes/Scheduled";
import InProgress from "components/Table/Commandes/InProgress";

import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select, { SelectChangeEvent } from '@material-ui/core/Select';


import { onSnapshot, collection, where } from "firebase/firestore";
import { db } from "firebase.jsx"; // add

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function Ticket() {
  const [age, setAge] = React.useState('');

  const handleChange2 = (event) => {
    setAge(event.target.value);
    console.log(event.target.value);
    const tmp = []
    switch (event.target.value) {
      case 'all':
        live.forEach(element => {
          tmp.push(element)
        });
        setDisplay(tmp)
        break;
      case 'cancelled':
        live.forEach(element => {
          if (element.status == 'cancelled') {
            tmp.push(element)
          }
        });
        setDisplay(tmp)
        break;
      case 'done':
        live.forEach(element => {
          if (element.status == 'done') {
            tmp.push(element)
          }
        });
        setDisplay(tmp)
        break;
      case 'scheduled':
        live.forEach(element => {
          if (element.status == 'scheduled') {
            tmp.push(element)
          }
        });
        setDisplay(tmp)
        break;
      case 'ongoing':
        live.forEach(element => {
          if (element.status != 'scheduled' && element.status != 'done' && element.status != 'cancelled') {
            tmp.push(element)
          }
        });
        setDisplay(tmp)
        break;
      default:
    }
  };
  const [live, setLive] = useState([]);
  const [display, setDisplay] = useState([]);
  const [str, setStr] = useState('');

  const handleChange = (event) => {
    const _data = [];
    live.forEach((doc) => {
      if (JSON.stringify(doc).includes(event.target.value)) {
        _data.push(doc)
      }
    });
    setStr(event.target.value)
    setDisplay(_data)
  };

  useEffect(() =>
    onSnapshot(collection(db, "orders"), (snapshot) => {
      const a = [];
      snapshot.forEach((doc) => {
        const _data = doc.data();
        a.push({ ..._data, id: doc.id });
      });
      const b = a.sort(function (a, b) {
        var textA = a.created_at.seconds;
        var textB = b.created_at.seconds;
        return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
      });
      //FAKE DATA
      setLive(b);
      setDisplay(b)
    }
    ),
    []
  );

  const classes = useStyles();

  const exportData = (event, param) => {
    console.log(display)
    // const rows = [
    //   ["First Name", "Last Name", "Address", "zipCode", "City", "Phone Number", "Money Earned", "Finished Rides", "Upcoming Rides", "Ongoing Rides"],
    // ];

    let csvContent = "data:text/csv;charset=utf-8,Status;Size;Name;Type;Price;User;Chaliar;Created At;PickUp Address;PickUp First Name;PickUp Last Name;PickUp Email;PickUp Phone;Drop Off Address;Drop Off First Name;Drop Off Last Name;Drop Off Email;Drop Off Phone;Chaliar First Name; Chaliar Last Name;Rating \r\n";
    let tmp = display
    tmp.forEach(function (rowArray) {
      let date = new Date(rowArray.created_at.seconds * 1000);
      console.log(date.toString());
      let stat = ""
      switch (rowArray.status) {
        case 'cancelled':
          stat = "Cancelled"
          break;
        case 'done':
          stat = "Finished"
          break;
        case 'scheduled':
          stat = "Scheduled"
          break;
        default:
          stat = "In Progress"
          break;

      }
      let row = `${stat};${rowArray.size};${rowArray.name};${rowArray.type};${(parseInt(rowArray.price) / 100).toString()};${rowArray.customerID};${rowArray.deliveryID};${date.toString()};${rowArray.adress};${rowArray.firstName};${rowArray.lastName};${rowArray.email};"${rowArray.phone}";${rowArray.adressReceiver};${rowArray.firstNameReceiver};${rowArray.lastNameReceiver};${rowArray.emailReceiver};"${rowArray.phoneReceiver}";${rowArray.firstNameDelivery};${rowArray.lastNameDelivery};${rowArray.isNoted.toString()}`
      csvContent += row + "\r\n";
      // ${(parseInt(rowArray.CA) * 0.9).toString()};${rowArray.done.length.toString()};${rowArray.scheduled.length.toString()};${rowArray.inprogress.length.toString()}
    });
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "orders.csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  return (
    <GridContainer>
      <TextField value={str} id="demo-helper-text-misaligned-no-helper" label="Rechercher" onChange={handleChange} fullWidth />
      <Box sx={{ minWidth: 200 }} pt={3}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={age}
            label="Age"
            onChange={handleChange2}
          >
            <MenuItem value={"all"}>Tous</MenuItem>
            <MenuItem value={"cancelled"}>Annulé</MenuItem>
            <MenuItem value={"done"}>Terminé</MenuItem>
            <MenuItem value={"scheduled"}>Programmé</MenuItem>
            <MenuItem value={"ongoing"}>En Cours</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="success">
            <h4 className={classes.cardTitleWhite}>Livraison Effectuées</h4>
            <p className={classes.cardCategoryWhite}>Livraison Effectuées</p>
          </CardHeader>
          <CardBody>
            <Done
              tableType="live"
              tableHeaderColor="warning"
              tableHead={[
                "Status",
                "Date",
                "Chaliar",
                "Utilisateur",
                "Détail",
                "Stripe",
              ]}
              tableData={display}
            />
          </CardBody>
        </Card>
        <Button variant="contained" color="primary" onClick={exportData}>Export</Button>
      </GridItem>
    </GridContainer>
  );
}
