// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyBAXFJNY5cMAMDcSvHTShsQPBNHrkxnfVc",
    authDomain: "chaliar-4b464.firebaseapp.com",
    projectId: "chaliar-4b464",
    storageBucket: "chaliar-4b464.appspot.com",
    messagingSenderId: "682679393819",
    appId: "1:682679393819:web:b84a1c8c4288d9c90662be",
    measurementId: "G-YSNN098PV3"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app)
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app, "europe-west1");