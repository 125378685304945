import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Check from "@material-ui/icons/Check";
import Delete from "@material-ui/icons/Delete";
import Close from "@material-ui/icons/Close";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ArrowForward from "@material-ui/icons/ArrowForward";
import { storage, functions } from "firebase.jsx"; // add
import { httpsCallable } from 'firebase/functions';
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import Edit from "@material-ui/icons/Edit";
import { db } from "firebase.jsx"; // add
import { doc, deleteDoc, updateDoc } from "firebase/firestore";

const useStyles = makeStyles(styles);

export default function Faq_FR(props) {
    const classes = useStyles();
    const { tableHead, tableData, tableHeaderColor, tableType } = props;


    const handleDelete = (parameter) => (event) => {
        console.log(parameter);
        console.log(tableData[parameter]);
        deleteDoc(doc(db, "faq", tableData[parameter].id));

    };



    const [open, setOpen] = React.useState(false);
    const [a, setA] = React.useState("");
    const [q, setQ] = React.useState("");
    const [id, setID] = React.useState();
    const handleClickOpen = (parameter) => (event) => {
        setID(tableData[parameter].id)
        setOpen(true);

    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = () => {
        updateDoc(doc(db, "faq", id), {
            "question": q,
            "reponse": a,
        });
        setOpen(false);
    };

    const questionChange = (event) => {
        setQ(event.target.value)
    };
    const answerChange = (event) => {
        setA(event.target.value)
    };


    return (
        <div className={classes.tableResponsive}>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Modifier la Question</DialogTitle>
                <DialogContent>
                    <TextField
                        onChange={questionChange}
                        autoFocus
                        margin="dense"
                        id="question"
                        label="Question"
                        type="email"
                        fullWidth
                        variant="standard"
                    />
                    <TextField
                        onChange={answerChange}
                        autoFocus
                        margin="dense"
                        id="answer"
                        label="Reponse"
                        type="email"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Annuler</Button>
                    <Button onClick={handleChange}>Valider</Button>
                </DialogActions>
            </Dialog>
            <Table className={classes.table}>
                {tableHead !== undefined ? (
                    <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                        <TableRow className={classes.tableHeadRow}>
                            {tableHead.map((prop, key) => {
                                return (
                                    <TableCell
                                        className={classes.tableCell + " " + classes.tableHeadCell}
                                        key={key}
                                    >
                                        {prop}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                ) : null}
                <TableBody>
                    {tableData.map((data, index) => {
                        return (<TableRow key={index} className={classes.tableBodyRow}>
                            {
                                Object.keys(data).map(function (pro) {
                                    if (pro == "question") {
                                        return (<TableCell key={pro} className={classes.tableCell}>
                                            {data[pro]}
                                        </TableCell>)
                                    }
                                })
                            }
                            {
                                Object.keys(data).map(function (pro) {
                                    if (pro == "reponse") {
                                        return (<TableCell key={pro} className={classes.tableCell}>
                                            {data[pro]}
                                        </TableCell>)
                                    }
                                })
                            }
                            {
                                Object.keys(data).map(function (pro) {
                                    if (pro == "language") {
                                        if (data[pro] == "french") {
                                            return (<TableCell key={pro} className={classes.tableCell}>
                                                Français
                                            </TableCell>)
                                        } else if (data[pro] == "english") {
                                            return (<TableCell key={pro} className={classes.tableCell}>
                                                Anglais
                                            </TableCell>)
                                        }
                                    }
                                })
                            }
                            <TableCell className={classes.tableCell}>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Editer"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <IconButton
                                        onClick={handleClickOpen(index)}
                                        aria-label="Accepter"
                                        className={classes.tableActionButton}
                                    >
                                        <Edit
                                            className={
                                                classes.tableActionButtonIcon +
                                                " " +
                                                classes.arrowForward
                                            }
                                        />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Supprimer"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <IconButton
                                        onClick={handleDelete(index)}
                                        aria-label="Refuser"
                                        className={classes.tableActionButton}
                                    >
                                        <Delete
                                            className={
                                                classes.tableActionButtonIcon +
                                                " " +
                                                classes.arrowForward
                                            }
                                        />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>

                        </TableRow>)
                    })}

                </TableBody>
            </Table>
        </div>
    );
}

Faq_FR.defaultProps = {
    tableHeaderColor: "gray",
};

Faq_FR.propTypes = {
    tableHeaderColor: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info",
        "rose",
        "gray",
    ]),
    tableHead: PropTypes.arrayOf(PropTypes.string),
    tableData: PropTypes.any,
    tableType: PropTypes.string,
};
