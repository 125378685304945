// ##############################
// // // javascript library for creating charts
// #############################
var Chartist = require("chartist");

// ##############################
// // // variables used to create animation on charts
// #############################
var delays = 80,
  durations = 500;
var delays2 = 80,
  durations2 = 500;

  
const weekB = {
  data: {
    labels: ["J-6", "J-5", "J-4", "J-3", "J-2", "J-1", "J"],
    series: [[0, 0, 0, 0, 0, 0, 0]],
  },
  options: {
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 0,
    }),
    low: 0,
    high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  // for animation
  // animation: {
  //   draw: function (data) {
  //     if (data.type === "line" || data.type === "area") {
  //       data.element.animate({
  //         d: {
  //           begin: 600,
  //           dur: 700,
  //           from: data.path
  //             .clone()
  //             .scale(1, 0)
  //             .translate(0, data.chartRect.height())
  //             .stringify(),
  //           to: data.path.clone().stringify(),
  //           easing: Chartist.Svg.Easing.easeOutQuint,
  //         },
  //       });
  //     } else if (data.type === "point") {
  //       data.element.animate({
  //         opacity: {
  //           begin: (data.index + 1) * delays,
  //           dur: durations,
  //           from: 0,
  //           to: 1,
  //           easing: "ease",
  //         },
  //       });
  //     }
  //   },
  // },
};
const monthB = {
  data: {
    labels: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
    series: [[3*8.5, 2*8.5, 0, 0, 0, 0, 5*8.5, 7*8.5, 4*8.5, 0, 2*8.5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
  },
  options: {
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 0,
    }),
    low: 0,
    high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  // for animation
  // animation: {
  //   draw: function (data) {
  //     if (data.type === "line" || data.type === "area") {
  //       data.element.animate({
  //         d: {
  //           begin: 600,
  //           dur: 700,
  //           from: data.path
  //             .clone()
  //             .scale(1, 0)
  //             .translate(0, data.chartRect.height())
  //             .stringify(),
  //           to: data.path.clone().stringify(),
  //           easing: Chartist.Svg.Easing.easeOutQuint,
  //         },
  //       });
  //     } else if (data.type === "point") {
  //       data.element.animate({
  //         opacity: {
  //           begin: (data.index + 1) * delays,
  //           dur: durations,
  //           from: 0,
  //           to: 1,
  //           easing: "ease",
  //         },
  //       });
  //     }
  //   },
  // },
};
const trimestreB = {
  data: {
    labels: ["", "", "", "", "", "", "", "", "", "", "", "Semaine Courante"],
    series: [[0, 0, 0, 0, 0, 0, 0, 13*8.5, 10*8.5, 0, 0, 0]],
  },
  options: {
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 0,
    }),
    low: 0,
    high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  // for animation
  // animation: {
  //   draw: function (data) {
  //     if (data.type === "line" || data.type === "area") {
  //       data.element.animate({
  //         d: {
  //           begin: 600,
  //           dur: 700,
  //           from: data.path
  //             .clone()
  //             .scale(1, 0)
  //             .translate(0, data.chartRect.height())
  //             .stringify(),
  //           to: data.path.clone().stringify(),
  //           easing: Chartist.Svg.Easing.easeOutQuint,
  //         },
  //       });
  //     } else if (data.type === "point") {
  //       data.element.animate({
  //         opacity: {
  //           begin: (data.index + 1) * delays,
  //           dur: durations,
  //           from: 0,
  //           to: 1,
  //           easing: "ease",
  //         },
  //       });
  //     }
  //   },
  // },
};
const yearB = {
  data: {
    labels: ["", "", "", "", "", "", "", "", "", "", "", "Mois Courant"],
    series: [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5*8.5, 18*8.5]],
  },
  options: {
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 0,
    }),
    low: 0,
    high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  // for animation
  // animation: {
  //   draw: function (data) {
  //     if (data.type === "line" || data.type === "area") {
  //       data.element.animate({
  //         d: {
  //           begin: 600,
  //           dur: 700,
  //           from: data.path
  //             .clone()
  //             .scale(1, 0)
  //             .translate(0, data.chartRect.height())
  //             .stringify(),
  //           to: data.path.clone().stringify(),
  //           easing: Chartist.Svg.Easing.easeOutQuint,
  //         },
  //       });
  //     } else if (data.type === "point") {
  //       data.element.animate({
  //         opacity: {
  //           begin: (data.index + 1) * delays,
  //           dur: durations,
  //           from: 0,
  //           to: 1,
  //           easing: "ease",
  //         },
  //       });
  //     }
  //   },
  // },
};

// ##############################
// // // Daily Sales
// #############################

const week = {
  data: {
    labels: ["J-6", "J-5", "J-4", "J-3", "J-2", "J-1", "J"],
    series: [[0, 0, 0, 0, 0, 0, 0]],
  },
  options: {
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 0,
    }),
    low: 0,
    high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  // for animation
  // animation: {
  //   draw: function (data) {
  //     if (data.type === "line" || data.type === "area") {
  //       data.element.animate({
  //         d: {
  //           begin: 600,
  //           dur: 700,
  //           from: data.path
  //             .clone()
  //             .scale(1, 0)
  //             .translate(0, data.chartRect.height())
  //             .stringify(),
  //           to: data.path.clone().stringify(),
  //           easing: Chartist.Svg.Easing.easeOutQuint,
  //         },
  //       });
  //     } else if (data.type === "point") {
  //       data.element.animate({
  //         opacity: {
  //           begin: (data.index + 1) * delays,
  //           dur: durations,
  //           from: 0,
  //           to: 1,
  //           easing: "ease",
  //         },
  //       });
  //     }
  //   },
  // },
};
const month = {
  data: {
    labels: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
    series: [[3, 2, 0, 0, 0, 0, 5, 7, 4, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
  },
  options: {
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 0,
    }),
    low: 0,
    high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  // for animation
  // animation: {
  //   draw: function (data) {
  //     if (data.type === "line" || data.type === "area") {
  //       data.element.animate({
  //         d: {
  //           begin: 600,
  //           dur: 700,
  //           from: data.path
  //             .clone()
  //             .scale(1, 0)
  //             .translate(0, data.chartRect.height())
  //             .stringify(),
  //           to: data.path.clone().stringify(),
  //           easing: Chartist.Svg.Easing.easeOutQuint,
  //         },
  //       });
  //     } else if (data.type === "point") {
  //       data.element.animate({
  //         opacity: {
  //           begin: (data.index + 1) * delays,
  //           dur: durations,
  //           from: 0,
  //           to: 1,
  //           easing: "ease",
  //         },
  //       });
  //     }
  //   },
  // },
};
const trimestre = {
  data: {
    labels: ["", "", "", "", "", "", "", "", "", "", "", "Semaine Courante"],
    series: [[0, 0, 0, 0, 0, 0, 0, 13, 10, 0, 0, 0]],
  },
  options: {
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 0,
    }),
    low: 0,
    high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  // for animation
  // animation: {
  //   draw: function (data) {
  //     if (data.type === "line" || data.type === "area") {
  //       data.element.animate({
  //         d: {
  //           begin: 600,
  //           dur: 700,
  //           from: data.path
  //             .clone()
  //             .scale(1, 0)
  //             .translate(0, data.chartRect.height())
  //             .stringify(),
  //           to: data.path.clone().stringify(),
  //           easing: Chartist.Svg.Easing.easeOutQuint,
  //         },
  //       });
  //     } else if (data.type === "point") {
  //       data.element.animate({
  //         opacity: {
  //           begin: (data.index + 1) * delays,
  //           dur: durations,
  //           from: 0,
  //           to: 1,
  //           easing: "ease",
  //         },
  //       });
  //     }
  //   },
  // },
};
const year = {
  data: {
    labels: ["", "", "", "", "", "", "", "", "", "", "", "Mois Courant"],
    series: [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5, 18]],
  },
  options: {
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 0,
    }),
    low: 0,
    high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  // for animation
  // animation: {
  //   draw: function (data) {
  //     if (data.type === "line" || data.type === "area") {
  //       data.element.animate({
  //         d: {
  //           begin: 600,
  //           dur: 700,
  //           from: data.path
  //             .clone()
  //             .scale(1, 0)
  //             .translate(0, data.chartRect.height())
  //             .stringify(),
  //           to: data.path.clone().stringify(),
  //           easing: Chartist.Svg.Easing.easeOutQuint,
  //         },
  //       });
  //     } else if (data.type === "point") {
  //       data.element.animate({
  //         opacity: {
  //           begin: (data.index + 1) * delays,
  //           dur: durations,
  //           from: 0,
  //           to: 1,
  //           easing: "ease",
  //         },
  //       });
  //     }
  //   },
  // },
};

// ##############################
// // // Email Subscriptions
// #############################

const emailsSubscriptionChart = {
  data: {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    series: [[542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895]],
  },
  options: {
    axisX: {
      showGrid: false,
    },
    low: 0,
    high: 1000,
    chartPadding: {
      top: 0,
      right: 5,
      bottom: 0,
      left: 0,
    },
  },
  responsiveOptions: [
    [
      "screen and (max-width: 640px)",
      {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function (value) {
            return value[0];
          },
        },
      },
    ],
  ],
  // animation: {
  //   draw: function (data) {
  //     if (data.type === "bar") {
  //       data.element.animate({
  //         opacity: {
  //           begin: (data.index + 1) * delays2,
  //           dur: durations2,
  //           from: 0,
  //           to: 1,
  //           easing: "ease",
  //         },
  //       });
  //     }
  //   },
  // },
};

// ##############################
// // // Completed Tasks
// #############################

const completedTasksChart = {
  data: {
    labels: ["M", "T", "W", "T", "F", "S", "S"],
    series: [[480, 170, 70, 170, 230, 180, 380]],
  },
  options: {
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 0,
    }),
    low: 0,
    high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  // animation: {
  //   draw: function (data) {
  //     if (data.type === "line" || data.type === "area") {
  //       data.element.animate({
  //         d: {
  //           begin: 600,
  //           dur: 700,
  //           from: data.path
  //             .clone()
  //             .scale(1, 0)
  //             .translate(0, data.chartRect.height())
  //             .stringify(),
  //           to: data.path.clone().stringify(),
  //           easing: Chartist.Svg.Easing.easeOutQuint,
  //         },
  //       });
  //     } else if (data.type === "point") {
  //       data.element.animate({
  //         opacity: {
  //           begin: (data.index + 1) * delays,
  //           dur: durations,
  //           from: 0,
  //           to: 1,
  //           easing: "ease",
  //         },
  //       });
  //     }
  //   },
  // },
};

module.exports = {
  week,
  month,
  year,
  trimestre,
  weekB,
  monthB,
  yearB,
  trimestreB,
  emailsSubscriptionChart,
  completedTasksChart,
};
