import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Visibility from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";


import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { ref, getDownloadURL, getMetadata, listAll, getBlob, deleteObject } from "firebase/storage";
import { onSnapshot, collection, where, updateDoc, doc } from "firebase/firestore";
import { storage, functions } from "firebase.jsx"; // add
import { httpsCallable } from 'firebase/functions';
import { db } from "firebase.jsx";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import * as mime from 'react-native-mime-types';

const useStyles = makeStyles(styles);

export default function CoursesTable(props) {
    const classes = useStyles();
    const { tableHead, tableData, tableHeaderColor, tableType } = props;

    const [open, setOpen] = React.useState(false);
    const [rejectRaison, setRejectRaison] = React.useState();
    const [userID, setuserID] = React.useState();
    const [userEmail, setuserEmail] = React.useState();

    const handleClick = (parameter, type) => async (event) => {
        event.preventDefault()
        const idRef = ref(storage, tableData[parameter].id);
        const folder = await listAll(idRef);
        const zip = new JSZip();
        for (const file of folder.items) {
            const fileRef = ref(storage, file.fullPath);
            const test = await getMetadata(fileRef)
            const fileBlob = await getBlob(fileRef)
            zip.file(`${file.fullPath}.${mime.extension(test.contentType)}`, fileBlob);
        }
        const blob = await zip.generateAsync({ type: "blob" });
        saveAs(blob, `${tableData[parameter].lastName}.zip`);
    }
    const reject = (parameter) => (event) => {
        event.preventDefault()
        setuserEmail(tableData[parameter].email)
        setuserID(tableData[parameter].id)
        setOpen(true);
        // let email = tableData[parameter].email;
        // let id = tableData[parameter].id;
        // const reject = httpsCallable(functions, 'rejectChaliar');
        // reject({ id: id, email: email })
        //     .then((result) => {
        //         console.log(result);
        //     }).catch((error) => {
        //         console.log(error);
        //     });
    }
    const accept = (parameter) => (event) => {
        event.preventDefault()
        updateDoc(doc(db, "users", tableData[parameter].id), {
            "okChaliar": true
        });
        let email = tableData[parameter].email;
        const accept = httpsCallable(functions, 'acceptChaliar');
        accept({ id: tableData[parameter].id })
            .then((result) => {
                console.log(result);
            }).catch((error) => {
                console.log(error);
            });
    }



    const acceptDL = (parameter) => (event) => {
        event.preventDefault()
        updateDoc(doc(db, "users", tableData[parameter].id), {
            "hasDL": true,
            "okDL": true,
            "badDL": false
        });
    }

    const acceptID = (parameter) => (event) => {
        event.preventDefault()
        updateDoc(doc(db, "users", tableData[parameter].id), {
            "hasID": true,
            "okID": true,
            "badID": false
        });
    }

    const acceptIBAN = (parameter) => (event) => {
        event.preventDefault()
        updateDoc(doc(db, "users", tableData[parameter].id), {
            "hasIBAN": true,
            "okIBAN": true,
            "badIBAN": false
        });
    }



    const handleClose = () => {
        setOpen(false);
    };
    const rejectChange = (event) => {
        setRejectRaison(event.target.value)
    };
    const handleCloseOk = () => {
        setOpen(false);

        console.log(userEmail)
        console.log(rejectRaison)
        console.log(userID)
        // const reject = httpsCallable(functions, 'rejectChaliar');
        // reject({ id: userID, email: userEmail, raison: rejectRaison })
        //     .then((result) => {
        //         console.log(result);
        //     }).catch((error) => {
        //         console.log(error);
        //     });
        setuserID("")
        setuserEmail("")
        setRejectRaison("")

    };










    const [openIDDid, setOpenIDDid] = React.useState("");
    const [openIDD, setOpenIDD] = React.useState(false);
    const [rejectIDDRaison, setRejectIDDRaison] = React.useState();
    const handleCloseIDD = () => {
        setOpenIDD(false);
    };
    const rejectIDDChange = (event) => {
        setRejectIDDRaison(event.target.value)
    };
    const handleCloseIDDOk = () => {
        setOpenIDD(false);
        const idRef = ref(storage, `${openIDDid}/ID`);
        deleteObject(idRef)
        updateDoc(doc(db, "users", openIDDid), {
            "hasID": false,
            "badID": true,
            "okID": false
        });
    };
    const rejectID = (parameter) => async (event) => {
        event.preventDefault()
        setOpenIDDid(tableData[parameter].id)
        setOpenIDD(true)
    }
















    const [openIBANid, setOpenIBANid] = React.useState("");
    const [openIBAN, setOpenIBAN] = React.useState(false);
    const [rejectIBANRaison, setRejectIBANRaison] = React.useState();
    const handleCloseIBAN = () => {
        setOpenIBAN(false);
    };
    const rejectIBANChange = (event) => {
        setRejectIBANRaison(event.target.value)
    };
    const handleCloseIBANOk = () => {
        setOpenIBAN(false);
        const idRef = ref(storage, `${openIBANid}/IBAN`);
        deleteObject(idRef)
        updateDoc(doc(db, "users", openIBANid), {
            "hasIBAN": false,
            "badIBAN": true,
            "okIBAN": false
        });
    };
    const rejectIBAN = (parameter) => async (event) => {
        event.preventDefault()
        setOpenIBANid(tableData[parameter].id)
        setOpenIBAN(true)
    }














    



    const [openDLid, setOpenDLid] = React.useState("");
    const [openDL, setOpenDL] = React.useState(false);
    const [rejectDLRaison, setRejectDLRaison] = React.useState();
    const handleCloseDL = () => {
        setOpenDL(false);
    };
    const rejectDLChange = (event) => {
        setRejectDLRaison(event.target.value)
    };
    const handleCloseDLOk = () => {
        setOpenDL(false);
        const idRef = ref(storage, `${openDLid}/DL`);
        deleteObject(idRef)
        updateDoc(doc(db, "users", openDLid), {
            "hasDL": false,
            "badDL": true,
            "okDL": false
        });
    };
    const rejectDL = (parameter) => async (event) => {
        event.preventDefault()
        setOpenDLid(tableData[parameter].id)
        setOpenDL(true)
    }
    return (
        <div className={classes.tableResponsive}>
            <Dialog open={openIDD} onClose={handleCloseIDD}>
                <DialogTitle>Rejeter la pièce d'identitée</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Vous êtes sur le point de rejeter la pièce d'identitée du Chaliar. Veuillez renseigner une raison.
                    </DialogContentText>
                    <TextField
                        onChange={rejectIDDChange}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Raison"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseIDD}>Annuler</Button>
                    <Button onClick={handleCloseIDDOk}>Valider</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openIBAN} onClose={handleCloseIBAN}>
                <DialogTitle>Rejeter un IBAN</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Vous êtes sur le point de rejeter l'IBAN du Chaliar. Veuillez renseigner une raison.
                    </DialogContentText>
                    <TextField
                        onChange={rejectIBANChange}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Raison"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseIBAN}>Annuler</Button>
                    <Button onClick={handleCloseIBANOk}>Valider</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openDL} onClose={handleCloseDL}>
                <DialogTitle>Rejeter un Permis de conduire</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Vous êtes sur le point de rejeter le permis de conduire du Chaliar. Veuillez renseigner une raison.
                    </DialogContentText>
                    <TextField
                        onChange={rejectDLChange}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Raison"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDL}>Annuler</Button>
                    <Button onClick={handleCloseDLOk}>Valider</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Rejeter un Chaliar</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Vous êtes sur le point de rejeter un Chaliar, veuillez renseigner une raison.
                    </DialogContentText>
                    <TextField
                        onChange={rejectChange}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Raison"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Annuler</Button>
                    <Button onClick={handleCloseOk}>Valider</Button>
                </DialogActions>
            </Dialog>
            <Table className={classes.table}>
                {tableHead !== undefined ? (
                    <TableHead className={classes[+ "TableHeader"]}>
                        <TableRow className={classes.tableHeadRtableHeaderColorow}>
                            {tableHead.map((prop, key) => {
                                console.log(tableHeaderColor)
                                return (
                                    <TableCell
                                        className={classes.tableCell + " " + classes.tableHeadCell}
                                        key={key}
                                    >
                                        {prop}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                ) : null}
                <TableBody>
                    {tableData.map((data, index) => {
                        console.log("TEST", data)
                        return (<TableRow key={index} className={classes.tableBodyRow}>
                            {
                                <TableCell key={'firstName'} className={classes.tableCell}>
                                    {data['firstName']}
                                </TableCell>
                            }

                            {
                                <TableCell key={'lastName'} className={classes.tableCell}>
                                    {data['lastName']}
                                </TableCell>
                            }
                            {
                                <TableCell key={'phone'} className={classes.tableCell}>
                                    {data['phone']}
                                </TableCell>
                            }
                            {
                                <TableCell key={'adress'} className={classes.tableCell}>
                                    {data['adress']}
                                </TableCell>
                            }
                            {
                                <TableCell key={'zipCode'} className={classes.tableCell}>
                                    {data['zipCode']}
                                </TableCell>
                            }
                            {
                                <TableCell key={'city'} className={classes.tableCell}>
                                    {data['city']}
                                </TableCell>
                            }
                            <TableCell className={classes.tableCell}>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Voir les documents"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <IconButton
                                        onClick={handleClick(index, "IDD")}

                                        aria-label="Voir les documents"
                                        className={classes.tableActionButton}
                                    >
                                        <Visibility
                                            className={
                                                classes.tableActionButtonIcon +
                                                " " +
                                                classes.arrowForward
                                            }
                                        />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            {
                                Object.keys(data).map(function (pro) {
                                    if (pro == "okDL") {
                                        if (data.okDL == true) {
                                            return (<TableCell key={pro} className={classes.tableCell}>
                                                Validé
                                            </TableCell>)
                                        } else if (data.hasDL) {
                                            return (<TableCell className={classes.tableCell} key={pro} >
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Accepter"
                                                    placement="top"
                                                    classes={{ tooltip: classes.tooltip }}
                                                >
                                                    <IconButton
                                                        onClick={acceptDL(index)}
                                                        aria-label="Accepter"
                                                        className={classes.tableActionButton}
                                                    >
                                                        <Check
                                                            className={
                                                                classes.tableActionButtonIcon +
                                                                " " +
                                                                classes.arrowForward
                                                            }
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Refuser"
                                                    placement="top"
                                                    classes={{ tooltip: classes.tooltip }}
                                                >
                                                    <IconButton
                                                        onClick={rejectDL(index)}
                                                        aria-label="Refuser"
                                                        className={classes.tableActionButton}
                                                    >
                                                        <Close
                                                            className={
                                                                classes.tableActionButtonIcon +
                                                                " " +
                                                                classes.arrowForward
                                                            }
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>)
                                        } else if (data.badDL) {
                                            return (<TableCell key={pro} className={classes.tableCell}>
                                                Rejeté
                                            </TableCell>)
                                        } else {
                                            return (<TableCell key={pro} className={classes.tableCell}>
                                                En Attente
                                            </TableCell>)
                                        }
                                    }
                                })
                            }
                            {
                                Object.keys(data).map(function (pro) {
                                    if (pro == "okIBAN") {
                                        if (data.okIBAN == true) {
                                            return (<TableCell key={pro} className={classes.tableCell}>
                                                Validé
                                            </TableCell>)
                                        } else if (data.hasIBAN) {
                                            return (<TableCell className={classes.tableCell} key={pro} >
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Accepter"
                                                    placement="top"
                                                    classes={{ tooltip: classes.tooltip }}
                                                >
                                                    <IconButton
                                                        onClick={acceptIBAN(index)}
                                                        aria-label="Accepter"
                                                        className={classes.tableActionButton}
                                                    >
                                                        <Check
                                                            className={
                                                                classes.tableActionButtonIcon +
                                                                " " +
                                                                classes.arrowForward
                                                            }
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Refuser"
                                                    placement="top"
                                                    classes={{ tooltip: classes.tooltip }}
                                                >
                                                    <IconButton
                                                        onClick={rejectIBAN(index)}
                                                        aria-label="Refuser"
                                                        className={classes.tableActionButton}
                                                    >
                                                        <Close
                                                            className={
                                                                classes.tableActionButtonIcon +
                                                                " " +
                                                                classes.arrowForward
                                                            }
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>)
                                        } else if (data.badIBAN) {
                                            return (<TableCell key={pro} className={classes.tableCell}>
                                                Rejeté
                                            </TableCell>)
                                        } else {
                                            return (<TableCell key={pro} className={classes.tableCell}>
                                                En Attente
                                            </TableCell>)
                                        }
                                    }
                                })
                            }
                            {
                                Object.keys(data).map(function (pro) {
                                    if (pro == "okID") {
                                        if (data.okID == true) {
                                            return (<TableCell key={pro} className={classes.tableCell}>
                                                Validé
                                            </TableCell>)
                                        } else if (data.hasID) {
                                            return (<TableCell className={classes.tableCell} key={pro} >
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Accepter"
                                                    placement="top"
                                                    classes={{ tooltip: classes.tooltip }}
                                                >
                                                    <IconButton
                                                        onClick={acceptID(index)}
                                                        aria-label="Accepter"
                                                        className={classes.tableActionButton}
                                                    >
                                                        <Check
                                                            className={
                                                                classes.tableActionButtonIcon +
                                                                " " +
                                                                classes.arrowForward
                                                            }
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Refuser"
                                                    placement="top"
                                                    classes={{ tooltip: classes.tooltip }}
                                                >
                                                    <IconButton
                                                        onClick={rejectID(index)}
                                                        aria-label="Refuser"
                                                        className={classes.tableActionButton}
                                                    >
                                                        <Close
                                                            className={
                                                                classes.tableActionButtonIcon +
                                                                " " +
                                                                classes.arrowForward
                                                            }
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>)
                                        } else if (data.badID) {
                                            return (<TableCell key={pro} className={classes.tableCell}>
                                                Rejeté
                                            </TableCell>)
                                        } else {
                                            return (<TableCell key={pro} className={classes.tableCell}>
                                                En Attente
                                            </TableCell>)
                                        }
                                    }
                                })
                            }{
                                Object.keys(data).map(function (pro) {
                                    if (pro == "okChaliar") {
                                        if (data.okDL && data.okIBAN && data.okID) {
                                            return (<TableCell className={classes.tableCell} key={pro} >
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Accepter"
                                                    placement="top"
                                                    classes={{ tooltip: classes.tooltip }}
                                                >
                                                    <IconButton
                                                        onClick={accept(index)}
                                                        aria-label="Accepter"
                                                        className={classes.tableActionButton}
                                                    >
                                                        <Check
                                                            className={
                                                                classes.tableActionButtonIcon +
                                                                " " +
                                                                classes.arrowForward
                                                            }
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Refuser"
                                                    placement="top"
                                                    classes={{ tooltip: classes.tooltip }}
                                                >
                                                    <IconButton
                                                        onClick={reject(index)}
                                                        aria-label="Refuser"
                                                        className={classes.tableActionButton}
                                                    >
                                                        <Close
                                                            className={
                                                                classes.tableActionButtonIcon +
                                                                " " +
                                                                classes.arrowForward
                                                            }
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>)
                                        } else {
                                            return (<TableCell className={classes.tableCell} key={pro} >
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Refuser"
                                                    placement="top"
                                                    classes={{ tooltip: classes.tooltip }}
                                                >
                                                    <IconButton
                                                        onClick={reject(index)}
                                                        aria-label="Refuser"
                                                        className={classes.tableActionButton}
                                                    >
                                                        <Close
                                                            className={
                                                                classes.tableActionButtonIcon +
                                                                " " +
                                                                classes.arrowForward
                                                            }
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>)
                                        }
                                    }
                                })
                            }

                        </TableRow>)
                    })}

                </TableBody>
            </Table>
        </div>
    );
}

CoursesTable.defaultProps = {
    tableHeaderColor: "gray",
};

CoursesTable.propTypes = {
    tableHeaderColor: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info",
        "rose",
        "gray",
    ]),
    tableHead: PropTypes.arrayOf(PropTypes.string),
    tableData: PropTypes.any,
    tableType: PropTypes.string,
};
