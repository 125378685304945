import React, { useState, useEffect } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
// import Warning from "@material-ui/icons/Warning";
// import DateRange from "@material-ui/icons/DateRange";
import Button from '@material-ui/core/Button';
// import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Courses from "components/Table/Courses.jsx";
// import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CardFooter from "components/Card/CardFooter.jsx";
import { onSnapshot, collection, where } from "firebase/firestore";
import { db } from "firebase.jsx"; // course
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import {
  week,
  month,
  trimestre,
  year,
  weekB,
  monthB,
  trimestreB,
  yearB,
  emailsSubscriptionChart,
  completedTasksChart,
} from "variables/charts.jsx";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    }
  ]);
  const [colors, setColors] = useState([{ name: "Loading...", id: "initial" }]);
  const [live, setLive] = useState([]);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [CA, setCA] = useState(0);
  const [total, setTotal] = useState(0);
  const [chaliar, setChaliar] = useState(0);
  const [daily, setDaily] = useState({});
  const [dailyOpt, setDailyOpt] = useState({});
  const [dailyB, setDailyB] = useState({});
  const [dailyBOpt, setDailyBOpt] = useState({});

  function getDay(date) {
    let day = date.getDate()
    let month = date.getMonth() + 1
    return day + "/" + month
  }

  useEffect(() =>
    onSnapshot(collection(db, "orders"), (snapshot) => {
      console.log("REFRESH IN ORDERS")
      let newDate = new Date()
      let one = new Date()
      let two = new Date()
      let three = new Date()
      let four = new Date()
      let five = new Date()
      let six = new Date()
      let seven = new Date()
      let _one = 0
      let _two = 0
      let _three = 0
      let _four = 0
      let _five = 0
      let _six = 0
      let _seven = 0
      let _oneB = 0
      let _twoB = 0
      let _threeB = 0
      let _fourB = 0
      let _fiveB = 0
      let _sixB = 0
      let _sevenB = 0
      one.setDate(newDate.getDate() - 7)
      two.setDate(newDate.getDate() - 6)
      three.setDate(newDate.getDate() - 5)
      four.setDate(newDate.getDate() - 4)
      five.setDate(newDate.getDate() - 3)
      six.setDate(newDate.getDate() - 2)
      seven.setDate(newDate.getDate() - 1)
      const live = [];
      const last = [];
      const pb = [];
      let CA = 0;
      snapshot.forEach((doc) => {
        const _data = doc.data();
        if (_data.status == "scheduled" || _data.status == "open" || _data.status == "pickup" || _data.status == "delivery") {
          live.push({ ..._data, id: doc.id });
        }
        if (_data.status == "done") {
          CA += parseInt(_data.price)
        }
        let test = new Date(_data.created_at.seconds * 1000)
        if ((test.getDay() == one.getDay()) && (test.getMonth() == one.getMonth()) && (test.getFullYear() == one.getFullYear())) {
          _oneB += (parseInt(_data.price) / 100) * 0.1
          _one += 1;
        } else if ((test.getDay() == two.getDay()) && (test.getMonth() == two.getMonth()) && (test.getFullYear() == two.getFullYear())) {
          _twoB += (parseInt(_data.price) / 100) * 0.1
          _two += 1;
        } else if ((test.getDay() == three.getDay()) && (test.getMonth() == three.getMonth()) && (test.getFullYear() == three.getFullYear())) {
          _threeB += (parseInt(_data.price) / 100) * 0.1
          _three += 1;
        } else if ((test.getDay() == four.getDay()) && (test.getMonth() == four.getMonth()) && (test.getFullYear() == four.getFullYear())) {
          _fourB += (parseInt(_data.price) / 100) * 0.1
          _four += 1;
        } else if ((test.getDay() == five.getDay()) && (test.getMonth() == five.getMonth()) && (test.getFullYear() == five.getFullYear())) {
          _fiveB += (parseInt(_data.price) / 100) * 0.1
          _five += 1;
        } else if ((test.getDay() == six.getDay()) && (test.getMonth() == six.getMonth()) && (test.getFullYear() == six.getFullYear())) {
          _sixB += (parseInt(_data.price) / 100) * 0.1
          _six += 1;
        } else if ((test.getDay() == seven.getDay()) && (test.getMonth() == seven.getMonth()) && (test.getFullYear() == seven.getFullYear())) {
          _sevenB += (parseInt(_data.price) / 100) * 0.1
          _seven += 1;
        }
        // postData.push({ ...doc.data(), id: doc.id });
      });
      setCount(snapshot.size);
      setLive(live);
      setCA(CA);
      setDaily({
        labels: [getDay(one), getDay(two), getDay(three), getDay(four), getDay(five), getDay(six), getDay(seven)],
        series: [[_one, _two, _three, _four, _five, _six, _seven]],
      })
      setDailyB({
        labels: [getDay(one), getDay(two), getDay(three), getDay(four), getDay(five), getDay(six), getDay(seven)],
        series: [[_oneB, _twoB, _threeB, _fourB, _fiveB, _sixB, _sevenB]],
      })

      setDailyBOpt({
        lineSmooth: Chartist.Interpolation.cardinal({
          tension: 0,
        }),
        low: 0,
        high: Math.max(...[_oneB, _twoB, _threeB, _fourB, _fiveB, _sixB, _sevenB]) * 2,
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      })
      setDailyOpt({
        lineSmooth: Chartist.Interpolation.cardinal({
          tension: 0,
        }),
        low: 0,
        high: Math.max(...[_one, _two, _three, _four, _five, _six, _seven]) + 5,
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      })


    }
    ),
    []
  );


  useEffect(() =>
    onSnapshot(collection(db, "users"), (snapshot) => {
      console.log("REFRESH IN USER")
      let chaliar = 0;
      snapshot.forEach((doc) => {
        const _data = doc.data();
        if (_data.isChaliar == true && _data.okChaliar == true) {
          chaliar += 1
        }
        // postData.push({ ...doc.data(), id: doc.id });
      });
      setTotal(snapshot.size);
      setChaliar(chaliar);
    }
    ),
    []
  );


  const handleSelect = () => {
    setOpen(!open)
  };
  const dateChange = (event) => {
    console.log(event)
    setState([event.selection])
  };

  const [selected, setSelected] = useState("7");

  const [selectedUpDownA, setSelectedUpDownA] = useState(-100);
  const [selectedUpDownB, setSelectedUpDownB] = useState(-100);
  const [selectData, setSelectData] = useState(week);
  const [selectDataB, setSelectDataB] = useState(weekB);
  const [a, setA] = useState("0");
  const [b, setB] = useState("0");
  const [c, setC] = useState("0");
  const handleInputChange = (event) => {
    console.log(event)
    if (event.target.value == "7") {
      setSelectData(week)
      setSelectDataB(weekB)
      setA("0")
      setB("0")
      setC("0")
      setSelectedUpDownA(-100)
      setSelectedUpDownB(-100)
    }
    else if (event.target.value == "30") {
      setSelectData(month)
      setSelectDataB(monthB)
      setA("23")
      setB("1851.5")
      setC("195.5")
      setSelectedUpDownA(32)
      setSelectedUpDownB(32)
    }
    else if (event.target.value == "90") {
      setSelectData(trimestre)
      setSelectDataB(trimestreB)
      setA("23")
      setB("1851.5")
      setC("195.5")
      setSelectedUpDownA(100)
      setSelectedUpDownB(100)
    }
    else if (event.target.value == "365") {
      setSelectData(year)
      setSelectDataB(yearB)
      setA("23")
      setB("1851.5")
      setC("195.5")
      setSelectedUpDownA(100)
      setSelectedUpDownB(100)
    }
    else if (event.target.value == "ALL") {
      setSelectData(year)
      setSelectDataB(yearB)
      setA("23")
      setB("1851.5")
      setC("195.5")
      setSelectedUpDownA(100)
      setSelectedUpDownB(100)
    }
    setSelected(event.target.value)
  };

  const classes = useStyles();
  return (
    <div>
      <Select
        onChange={handleInputChange}
        margin="dense"
        variant="standard"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selected}
        name="type"
        label="Age"
      >
        <MenuItem value={"7"}>7 derniers jours</MenuItem>
        <MenuItem value={"30"}>30 derniers jours</MenuItem>
        <MenuItem value={"90"}>90 derniers jours</MenuItem>
        <MenuItem value={"365"}>Dernière année</MenuItem>
        <MenuItem value={"ALL"}>Total</MenuItem>
      </Select>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>content_copy</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Courses</p>
              {/* <h3 className={classes.cardTitle}>{count}</h3> */}
              <h3 className={classes.cardTitle}>{a}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Mise à jour à l&apos;instant
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>CA</p>
              {/* <h3 className={classes.cardTitle}>{(CA / 100).toFixed(2)} €</h3> */}
              <h3 className={classes.cardTitle}>{b}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Mise à jour à l&apos;instant
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Bénéfice</p>
              {/* <h3 className={classes.cardTitle}>{((CA / 100) * 0.1).toFixed(2)} €</h3> */}
              <h3 className={classes.cardTitle}>{c}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Mise à jour à l&apos;instant
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Accessibility />
              </CardIcon>
              <p className={classes.cardCategory}>Chaliar Actif</p>
              <h3 className={classes.cardTitle}>{chaliar}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Mise à jour à l&apos;instant
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card chart>
            <CardHeader color="success">
              <ChartistGraph

                className="ct-chart"
                data={selectData.data}
                type="Line"
                options={selectData.option}
                listener={selectData.animation}
              // className="ct-chart"
              // data={daily}
              // type="Line"
              // options={dailyOpt}
              // listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Courses Quotidiennes</h4>
              <p className={classes.cardCategory}>
                {
                  (selectedUpDownA >= 0) ? <span className={classes.successText}>
                    <ArrowUpward className={classes.upArrowCardCategory} /> {Math.abs(selectedUpDownA)} %
                  </span> : <span className={classes.dangerText}>
                    <ArrowDownward className={classes.upArrowCardCategory} /> {Math.abs(selectedUpDownA)} %
                  </span>
                }
              </p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> Mise à jour à l&apos;instant
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card chart>
            <CardHeader color="success">
              <ChartistGraph
                className="ct-chart"
                data={selectDataB.data}
                type="Line"
                options={selectDataB.option}
                listener={selectDataB.animation}
              // className="ct-chart"
              // data={dailyB}
              // type="Line"
              // options={dailyBOpt}
              // listener={completedTasksChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Bénéfice</h4>
              <p className={classes.cardCategory}>
                {
                  (selectedUpDownB >= 0) ? <span className={classes.successText}>
                    <ArrowUpward className={classes.upArrowCardCategory} /> {Math.abs(selectedUpDownB)} %
                  </span> : <span className={classes.dangerText}>
                    <ArrowDownward className={classes.upArrowCardCategory} /> {Math.abs(selectedUpDownB)} %
                  </span>
                }

              </p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> Mise à jour à l&apos;instant
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={5}>
          <Card>
            <CardHeader color="danger">
              <h4 className={classes.cardTitleWhite}>Tickets</h4>
              <p className={classes.cardCategoryWhite}>Problème en cours</p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="danger"
                tableHead={["ID", "ID Livraison", "Date", "Ville", "Action"]}
                tableData={[]}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={7}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Courses</h4>
              <p className={classes.cardCategoryWhite}>Courses en cours</p>
            </CardHeader>
            <CardBody>
              <Courses
                tableType="live"
                tableHeaderColor="warning"
                tableHead={[
                  "Status",
                  "Utilisateur",
                  "Chaliar",
                  "Prix",
                  "Ville",
                  "Action",
                ]}
                tableData={live}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
