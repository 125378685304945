import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Visibility from "@material-ui/icons/Visibility";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

import { db, storage, functions } from "firebase.jsx"; // add
import { httpsCallable } from 'firebase/functions';
import { onSnapshot, collection, where, getDoc, doc } from "firebase/firestore";


import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import UserModal from "components/Fiches/User.jsx";
import ChaliarModal from "components/Fiches/Chaliar.jsx";
import CommandeModal from "components/Fiches/Commande.jsx";
const useStyles = makeStyles(styles);

export default function CoursesTable(props) {
    const classes = useStyles();
    const { tableHead, tableData, tableHeaderColor, tableType } = props;


    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState({});
    const view = (parameter) => (event) => {
        event.preventDefault()
        console.log("parameter", parameter);
        setOpen(true);
        setData(parameter)
    }
    const handleClose = () => {
        setOpen(false);
    };





    const [openChaliarModal, setOpenChaliarModal] = React.useState(false);
    const [dataChaliarModal, setDataChaliarModal] = React.useState({});

    const [openUserModal, setOpenUserModal] = React.useState(false);
    const [dataUserModal, setDataUserModal] = React.useState({});

    const testChaliar = async (id) => {
        if (id) {
            console.log("CHALIAR ID", id);
            const docRef2 = doc(db, "users", id);
            const docSnap2 = await getDoc(docRef2);
            const _data2 = docSnap2.data()
            console.log("CHALIAR DATA", _data2);
            setDataChaliarModal({ ..._data2, id: id })
            setOpenChaliarModal(true)
        }
    };

    const handleCloseChaliarModal = () => {
        setOpenChaliarModal(false);
    };




    const testUser = async (id) => {
        console.log("USER ID", id);
        const docRef1 = doc(db, "users", id);
        const docSnap1 = await getDoc(docRef1);
        const _data1 = docSnap1.data()
        console.log("USER DATA", _data1);
        setDataUserModal({ ..._data1, id: id })
        setOpenUserModal(true)
    };

    const handleCloseUserModal = () => {
        setOpenUserModal(false);
    };






    const [openCommandModal, setOpenCommandModal] = React.useState(false);
    const [dataCommandModal, setDataCommandModal] = React.useState({});

    const testCommand = async (data) => {
        let userData = {}
        if ("customerID" in data) {

            const userRef = doc(db, "users", data.customerID);
            const userSnap = await getDoc(userRef);
            userData = userSnap.data()
        } else {
            userData = {}

        }


        let chaliarData = {}
        if ("deliveryID" in data) {


            const chaliarRef = doc(db, "users", data.deliveryID);
            const chaliarSnap = await getDoc(chaliarRef);
            chaliarData = chaliarSnap.data()
        } else {
            chaliarData = {}

        }



        const fullCommandData = {
            order: data,
            orderChaliar: { ...chaliarData, id: ("deliveryID" in data) ? data.deliveryID : null },
            orderUser: { ...userData, id: ("customerID" in data) ? data.customerID : null }
        }
        setDataCommandModal(fullCommandData)
        console.log(fullCommandData)
        setOpenCommandModal(true)
    };

    const handleCloseCommandModal = () => {
        setOpenCommandModal(false);
    };



    return (
        <div className={classes.tableResponsive}>

            <Dialog
                fullWidth
                open={openChaliarModal}
                onClose={handleCloseChaliarModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Chaliar
                </DialogTitle>
                <DialogContent>
                    <ChaliarModal userData={dataChaliarModal}></ChaliarModal>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseChaliarModal}>Fermer</Button>
                </DialogActions>
            </Dialog>


            <Dialog
                fullWidth
                open={openUserModal}
                onClose={handleCloseUserModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Utilisateur
                </DialogTitle>
                <DialogContent>
                    <UserModal userData={dataUserModal}></UserModal>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUserModal}>Fermer</Button>
                </DialogActions>
            </Dialog>




            <Dialog
                fullWidth
                open={openCommandModal}
                onClose={handleCloseCommandModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Commande
                </DialogTitle>
                <DialogContent>
                    <CommandeModal userData={dataCommandModal}></CommandeModal>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCommandModal}>Fermer</Button>
                </DialogActions>
            </Dialog>



            {/* <Dialog
                fullWidth
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {data.id}
                </DialogTitle>
                <DialogContent>
                    <h4 STYLE="padding:0 0 0 0px;margin:0">Colis</h4>
                    <p STYLE="padding:0 0 0 20px;;margin:0">Nom:<span STYLE="padding:0 0 0 20px;">{data.name}</span></p>
                    <p STYLE="padding:0 0 0 20px;;margin:0">Taille:<span STYLE="padding:0 0 0 20px;">{data.size}</span></p>
                    <h4 STYLE="padding:0 0 0 0px;margin:0">Livraison</h4>
                    <p STYLE="padding:0 0 0 20px;;margin:0">Status:<span STYLE="padding:0 0 0 20px;">{data.status}</span></p>
                    <p STYLE="padding:0 0 0 20px;;margin:0">Note:<span STYLE="padding:0 0 0 20px;">{data.note}</span></p>
                    <p STYLE="padding:0 0 0 20px;;margin:0">Type:<span STYLE="padding:0 0 0 20px;">{data.type}</span></p>
                    <p STYLE="padding:0 0 0 20px;;margin:0">Départ:<span STYLE="padding:0 0 0 20px;">{data.adress}</span></p>
                    <p STYLE="padding:0 0 0 20px;;margin:0">Arrivé: <span STYLE="padding:0 0 0 20px;">{data.adressReceiver}</span></p>
                    <h4 STYLE="padding:0 0 0 0px;;margin:0">Chaliar</h4>
                    <p STYLE="padding:0 0 0 20px;;margin:0">ID: <span STYLE="padding:0 0 0 20px;">{data.deliveryID}</span></p>
                    <p STYLE="padding:0 0 0 20px;;margin:0">Prénom: <span STYLE="padding:0 0 0 20px;">{data.firstNameDelivery}</span></p>
                    <p STYLE="padding:0 0 0 20px;;margin:0">nom: <span STYLE="padding:0 0 0 20px;">{data.lastNameDelivery}</span></p>
                    <p STYLE="padding:0 0 0 20px;;margin:0">téléphone: <span STYLE="padding:0 0 0 20px;">{data.phoneDelivery}</span></p>
                    <h4 STYLE="padding:0 0 0 0px;;margin:0">Client</h4>
                    <p STYLE="padding:0 0 0 20px;;margin:0">ID: <span STYLE="padding:0 0 0 20px;">{data.customerID}</span></p>
                    <p STYLE="padding:0 0 0 20px;;margin:0">Prénom: <span STYLE="padding:0 0 0 20px;">{data.firstName}</span></p>
                    <p STYLE="padding:0 0 0 20px;;margin:0">nom: <span STYLE="padding:0 0 0 20px;">{data.lastName}</span></p>
                    <p STYLE="padding:0 0 0 20px;;margin:0">téléphone: <span STYLE="padding:0 0 0 20px;">{data.phone}</span></p>
                    <h4 STYLE="padding:0 0 0 0px;;margin:0">Récepteur</h4>
                    <p STYLE="padding:0 0 0 20px;;margin:0">Prénom: <span STYLE="padding:0 0 0 20px;">{data.firstNameReceiver}</span></p>
                    <p STYLE="padding:0 0 0 20px;;margin:0">nom: <span STYLE="padding:0 0 0 20px;">{data.lastNameReceiver}</span></p>
                    <p STYLE="padding:0 0 0 20px;;margin:0">téléphone: <span STYLE="padding:0 0 0 20px;">{data.phoneReceiver}</span></p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>
            </Dialog> */}
            <Table className={classes.table}>
                {tableHead !== undefined ? (
                    <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                        <TableRow className={classes.tableHeadRow}>
                            {tableHead.map((prop, key) => {
                                return (
                                    <TableCell
                                        className={classes.tableCell + " " + classes.tableHeadCell}
                                        key={key}
                                    >
                                        {prop}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                ) : null}
                <TableBody>
                    {tableData.map((data, index) => {
                        let displayStatus = "???"
                        switch (data.status) {
                            case 'cancelled':
                                displayStatus = "Annulée"
                                break;
                            case 'done':
                                displayStatus = "Terminée"
                                break;
                            case 'scheduled':
                                displayStatus = "Programmée"
                                break;
                            default:
                                displayStatus = "En Cours"
                                break;

                        }

                        return (<TableRow key={index} className={classes.tableBodyRow}>
                            {

                                <TableCell key="status" className={classes.tableCell}>
                                    {displayStatus}
                                </TableCell>
                            }

                            {

                                <TableCell key="date" className={classes.tableCell}>
                                    {new Date(data.schedule.seconds * 1000).toDateString()}
                                </TableCell>
                            }
                            {

                                <TableCell key="chaliar" className={classes.tableCellClick} onClick={() => testChaliar(data.deliveryID)}>
                                    {data.deliveryID}
                                </TableCell>
                            }
                            {

                                <TableCell key="user" className={classes.tableCellClick} onClick={() => testUser(data.customerID)}>
                                    {data.customerID}
                                </TableCell>
                            }

                            <TableCell className={classes.tableCell}>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Voir toutes les informations de la commande"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <IconButton
                                        onClick={() => testCommand(data)}

                                        aria-label="Voir les documents"
                                        className={classes.tableActionButton}
                                    >
                                        <Visibility
                                            className={
                                                classes.tableActionButtonIcon +
                                                " " +
                                                classes.arrowForward
                                            }
                                        />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Acceder à la page Stripe liée au Paiement"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <IconButton
                                        href={`https://dashboard.stripe.com/test/payments/${data.paymentID}`}
                                        target="_blank"
                                        aria-label="Stripe"
                                        className={classes.tableActionButton}
                                    >
                                        <Visibility
                                            className={
                                                classes.tableActionButtonIcon +
                                                " " +
                                                classes.arrowForward
                                            }
                                        />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>



                        </TableRow>)
                    })}

                </TableBody>
            </Table>
        </div>
    );
}

CoursesTable.defaultProps = {
    tableHeaderColor: "gray",
};

CoursesTable.propTypes = {
    tableHeaderColor: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info",
        "rose",
        "gray",
    ]),
    tableHead: PropTypes.arrayOf(PropTypes.string),
    tableData: PropTypes.any,
    tableType: PropTypes.string,
};
