import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Container from "@material-ui/core/Container";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Visibility from "@material-ui/icons/Visibility";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import { httpsCallable } from 'firebase/functions';
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { onSnapshot, collection, where, getDoc, doc } from "firebase/firestore";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Slider from "@material-ui/core/Slider";

import logo from "assets/img/anonymous.png";

import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Collapse from "@material-ui/core/Collapse";

import { db, storage, functions, auth } from "firebase.jsx"; // course
import { ref, getDownloadURL, getMetadata, listAll, getBlob, deleteObject } from "firebase/storage";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import * as mime from 'react-native-mime-types';
import UserModal from "components/Fiches/User.jsx";
import ChaliarModal from "components/Fiches/Chaliar.jsx";


const useStyles = makeStyles(styles);
export default function CommandeModal(props) {
    const classes = useStyles();
    const { userData } = props;

    const [urlImg, setUrlImg] = React.useState("anonymous");

    const [orderLive, setOrderLive] = React.useState(false);
    const [orderDone, setOrderDone] = React.useState(false);
    const [ticketLive, setTicketLive] = React.useState(false);
    const [ticketDone, setTicketDone] = React.useState(false);



    useEffect(async () => {
        let _store = ref(storage, `orders/${userData.order.id}`)
        try {
            const url = await getDownloadURL(_store)
            setUrlImg(url)
        } catch (error) {
            setUrlImg("anonymous")
        }
    });

    const getStatusFR = () => {
        switch (userData.order.status) {
            case 'cancelled':
                return <p className={classes.testRight}>Annulée</p>;
            case 'done':
                return <p className={classes.testRight}>Terminée</p>;
            default:
                return <p className={classes.testRight}>En cours</p>;

        }
    };



    const [openUserModal, setOpenUserModal] = React.useState(false);
    const [dataUserModal, setDataUserModal] = React.useState({});
    const test = () => {
        setDataUserModal(userData.orderUser)
        setOpenUserModal(true)
    };
    const handleCloseUserModal = () => {
        setOpenUserModal(false);
    };


    const [openChaliarModal, setOpenChaliarModal] = React.useState(false);
    const [dataChaliarModal, setDataChaliarModal] = React.useState({});
    const testChaliar = () => {
        setDataChaliarModal(userData.orderChaliar)
        setOpenChaliarModal(true)
    };
    const handleCloseChaliarModal = () => {
        setOpenChaliarModal(false);
    };

    return (
        <div>
            <Dialog
                fullWidth
                open={openUserModal}
                onClose={handleCloseUserModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {userData.orderUser.id}
                </DialogTitle>
                <DialogContent>
                    <UserModal userData={dataUserModal}></UserModal>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUserModal}>Fermer</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth
                open={openChaliarModal}
                onClose={handleCloseChaliarModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {userData.orderChaliar.id}
                </DialogTitle>
                <DialogContent>
                    <ChaliarModal userData={dataChaliarModal}></ChaliarModal>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseChaliarModal}>Fermer</Button>
                </DialogActions>
            </Dialog>



            <Grid container spacing={2} direction="column"

            >
                <Grid item xs={12} >
                    <div className={classes.logoImage}>
                        <img src={(urlImg != "anonymous") ? urlImg : logo} alt="logo" className={classes.imgRec} />
                    </div>
                </Grid>
                <Grid item xs={12} >
                    <Card>
                        <CardHeader
                            title="INFORMATIONS"
                        >
                        </CardHeader>
                        <CardContent>
                            <p className={classes.testLeft}>Status</p>
                            {getStatusFR()}
                            <p className={classes.testLeft}>Nom</p>
                            <p className={classes.testRight}>{userData.order.name}</p>
                            <p className={classes.testLeft}>Adresse de départ</p>
                            <p className={classes.testRight}>{userData.order.adress}</p>
                            <p className={classes.testLeft}>Adresse d'arrivée</p>
                            <p className={classes.testRight}>{userData.order.adressReceiver}</p>
                            <p className={classes.testLeft}>Création</p>
                            <p className={classes.testRight}>{new Date(userData.order.created_at.seconds * 1000).toUTCString()}</p>
                            {(userData.orderChaliar.id) ? <p className={classes.testLeft}>Livreur</p>
                                : null}
                            {(userData.orderChaliar.id) ? <p className={classes.testRightClick} onClick={() => testChaliar()}>{userData.orderChaliar.firstName} {userData.orderChaliar.lastName}</p>
                                : null}

                            <p className={classes.testLeft}>Utilisateur</p>
                            <p className={classes.testRightClick} onClick={() => test()}>{userData.orderUser.firstName} {userData.orderUser.lastName}</p>
                            <p className={classes.testLeft}>Note</p>
                            <p className={classes.testRight}>{(userData.order.isNoted) ? userData.order.note : 'Course non notée'}</p>
                            <p className={classes.testLeft}>Identifiant de paiement</p>
                            <p className={classes.testRight}>{userData.order.paymentID}</p>
                            <p className={classes.testLeft}>Identifiant du document STRIPE</p>
                            <p className={classes.testRight}>{userData.order.stripeDocID}</p>
                            <p className={classes.testLeft}>Taille du colis</p>
                            <p className={classes.testRight}>{userData.order.size}</p>
                            <p className={classes.testLeft}>Prix</p>
                            <p className={classes.testRight}>{(parseInt(userData.order.price) / 100).toFixed(2)} €</p>

                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} >
                    <Card>
                        <CardHeader
                            title="Contact (récupération)"
                            action={
                                <IconButton
                                    onClick={() => setOrderLive(!orderLive)}
                                    aria-label="expand"
                                >
                                    {orderLive ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            }
                        >
                        </CardHeader>
                        <div>
                            <Collapse in={orderLive} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <p className={classes.testLeft}>Adresse</p>
                                    <p className={classes.testRight}>{userData.order.adress}</p>
                                    <p className={classes.testLeft}>Entreprise</p>
                                    <p className={classes.testRight}>{userData.order.company}</p>
                                    <p className={classes.testLeft}>Email</p>
                                    <p className={classes.testRight}>{userData.order.email}</p>
                                    <p className={classes.testLeft}>Prénom</p>
                                    <p className={classes.testRight}>{userData.order.firstName}</p>
                                    <p className={classes.testLeft}>Nom</p>
                                    <p className={classes.testRight}>{userData.order.lastName}</p>
                                    <p className={classes.testLeft}>Téléphone</p>
                                    <p className={classes.testRight}>{userData.order.phone}</p>
                                </CardContent>

                            </Collapse>
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            title="Contact (livraison)"
                            action={
                                <IconButton
                                    onClick={() => setOrderDone(!orderDone)}
                                    aria-label="expand"
                                >
                                    {orderDone ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            }
                        >
                        </CardHeader>
                        <div>
                            <Collapse in={orderDone} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <p className={classes.testLeft}>Adresse</p>
                                    <p className={classes.testRight}>{userData.order.adressReceiver}</p>
                                    <p className={classes.testLeft}>Entreprise</p>
                                    <p className={classes.testRight}>{userData.order.companyReceiver}</p>
                                    <p className={classes.testLeft}>Email</p>
                                    <p className={classes.testRight}>{userData.order.emailReceiver}</p>
                                    <p className={classes.testLeft}>Prénom</p>
                                    <p className={classes.testRight}>{userData.order.firstNameReceiver}</p>
                                    <p className={classes.testLeft}>Nom</p>
                                    <p className={classes.testRight}>{userData.order.lastNameReceiver}</p>
                                    <p className={classes.testLeft}>Téléphone</p>
                                    <p className={classes.testRight}>{userData.order.phoneReceiver}</p>
                                </CardContent>

                            </Collapse>
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={() => { window.open(`${window.location.origin}/admin/map?id=${userData.order.id}`, '_blank').focus(); }}>Ouvrir sur la carte</Button>
                </Grid>
            </Grid>
        </div >
    );
}


CommandeModal.propTypes = {
    userData: PropTypes.any,
};
