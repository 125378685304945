import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Visibility from "@material-ui/icons/Visibility";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { ref, getDownloadURL } from "firebase/storage";
import { storage, functions } from "firebase.jsx"; // add
import { httpsCallable } from 'firebase/functions';

const useStyles = makeStyles(styles);

export default function CoursesTable(props) {
    const classes = useStyles();
    const { tableHead, tableData, tableHeaderColor, tableType } = props;

    // const handleClick = (parameter, type) => (event) => {
    //     event.preventDefault()
    //     if (type == "permis") {
    //         const licenseRef = ref(storage, tableData[parameter].id + '/license');
    //         getDownloadURL(licenseRef).then((url) => {
    //             console.log(url)
    //             window.open(url, '_blank').focus();
    //         }).catch(console.error);
    //     } else if (type == "IDD") {
    //         const idRef = ref(storage, tableData[parameter].id + '/ID');
    //         getDownloadURL(idRef).then((url) => {
    //             console.log(url)
    //             window.open(url, '_blank').focus();
    //         }).catch(console.error);
    //     }
    // }
    // const reject = (parameter) => (event) => {
    //     event.preventDefault()
    //     let email = tableData[parameter].email;
    //     let id = tableData[parameter].id;
    //     const reject = httpsCallable(functions, 'rejectChaliar');
    //     reject({ id: id, email: email })
    //         .then((result) => {
    //             console.log(result);
    //         }).catch((error) => {
    //             console.log(error);
    //         });
    // }
    // const accept = (parameter) => (event) => {
    //     event.preventDefault()
    //     console.log(tableData[parameter])
    //     let email = tableData[parameter].email;
    //     let id = tableData[parameter].id;
    //     let city = tableData[parameter].city;
    //     let address = tableData[parameter].address;
    //     let first = tableData[parameter].first;
    //     let last = tableData[parameter].last;
    //     let phone = tableData[parameter].phone;
    //     let type = tableData[parameter].type;
    //     let zip = tableData[parameter].zip;
    //     const accept = httpsCallable(functions, 'acceptChaliar');
    //     accept({ id: id, email: email, city: city, address: address, first: first, last: last, phone: phone, type: type, zip: zip })
    //         .then((result) => {
    //             console.log(result);
    //         }).catch((error) => {
    //             console.log(error);
    //         });
    // }

    return (
        <div className={classes.tableResponsive}>
            <Table className={classes.table}>
                {tableHead !== undefined ? (
                    <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                        <TableRow className={classes.tableHeadRow}>
                            {tableHead.map((prop, key) => {
                                return (
                                    <TableCell
                                        className={classes.tableCell + " " + classes.tableHeadCell}
                                        key={key}
                                    >
                                        {prop}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                ) : null}
                <TableBody>
                    {tableData.map((data, index) => {
                        return (<TableRow key={index} className={classes.tableBodyRow}>
                            <TableCell className={classes.tableCell}>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Voir les informations du Livreur"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <IconButton
                                        onClick={null}

                                        aria-label="Voir les documents"
                                        className={classes.tableActionButton}
                                    >
                                        <Visibility
                                            className={
                                                classes.tableActionButtonIcon +
                                                " " +
                                                classes.arrowForward
                                            }
                                        />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Voir les informations de l'utilisateur"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <IconButton
                                        onClick={null}

                                        aria-label="Voir les documents"
                                        className={classes.tableActionButton}
                                    >
                                        <Visibility
                                            className={
                                                classes.tableActionButtonIcon +
                                                " " +
                                                classes.arrowForward
                                            }
                                        />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Voir les informations de la Livraison"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <IconButton
                                        onClick={null}

                                        aria-label="Voir les documents"
                                        className={classes.tableActionButton}
                                    >
                                        <Visibility
                                            className={
                                                classes.tableActionButtonIcon +
                                                " " +
                                                classes.arrowForward
                                            }
                                        />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            {

                                <TableCell key="date" className={classes.tableCell}>
                                    {new Date(data.schedule.seconds * 1000).toDateString()}
                                </TableCell>
                            }
                            <TableCell className={classes.tableCell}>
                                <Tooltip
                                    id="tooltip-top"
                                    title="A faire?"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <IconButton
                                        onClick={null}

                                        aria-label="Voir toutes les informations"
                                        className={classes.tableActionButton}
                                    >
                                        <Edit
                                            className={
                                                classes.tableActionButtonIcon +
                                                " " +
                                                classes.arrowForward
                                            }
                                        />
                                    </IconButton>
                                </Tooltip>

                            </TableCell>


                        </TableRow>)
                    })}

                </TableBody>
            </Table>
        </div>
    );
}

CoursesTable.defaultProps = {
    tableHeaderColor: "gray",
};

CoursesTable.propTypes = {
    tableHeaderColor: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info",
        "rose",
        "gray",
    ]),
    tableHead: PropTypes.arrayOf(PropTypes.string),
    tableData: PropTypes.any,
    tableType: PropTypes.string,
};
