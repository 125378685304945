import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Visibility from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";



import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import UserModal from "components/Fiches/User.jsx";
import ChaliarModal from "components/Fiches/Chaliar.jsx";
import CommandeModal from "components/Fiches/Commande.jsx";

// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";


import { db } from "firebase.jsx";
import { getDoc, doc } from "firebase/firestore";

const useStyles = makeStyles(styles);

export default function CoursesTable(props) {
    const classes = useStyles();
    const { tableHead, tableData, tableHeaderColor, tableType } = props;
    const follow = (parameter) => (event) => {
        event.preventDefault()
        window.open(`${window.location.origin}/admin/map?id=${tableData[parameter].id}`, '_blank').focus();

    }





    const [openChaliarModal, setOpenChaliarModal] = React.useState(false);
    const [dataChaliarModal, setDataChaliarModal] = React.useState({});

    const [openUserModal, setOpenUserModal] = React.useState(false);
    const [dataUserModal, setDataUserModal] = React.useState({});

    const testChaliar = async (id) => {
        if (id) {
            console.log("CHALIAR ID", id);
            const docRef2 = doc(db, "users", id);
            const docSnap2 = await getDoc(docRef2);
            const _data2 = docSnap2.data()
            console.log("CHALIAR DATA", _data2);
            setDataChaliarModal({ ..._data2, id: id })
            setOpenChaliarModal(true)
        }
    };

    const handleCloseChaliarModal = () => {
        setOpenChaliarModal(false);
    };




    const testUser = async (id) => {
        console.log("USER ID", id);
        const docRef1 = doc(db, "users", id);
        const docSnap1 = await getDoc(docRef1);
        const _data1 = docSnap1.data()
        console.log("USER DATA", _data1);
        setDataUserModal({ ..._data1, id: id })
        setOpenUserModal(true)
    };

    const handleCloseUserModal = () => {
        setOpenUserModal(false);
    };






    const [openCommandModal, setOpenCommandModal] = React.useState(false);
    const [dataCommandModal, setDataCommandModal] = React.useState({});

    const testCommand = async (data) => {
        let userData = {}
        if ("customerID" in data) {

            const userRef = doc(db, "users", data.customerID);
            const userSnap = await getDoc(userRef);
            userData = userSnap.data()
        } else {
            userData = {}

        }


        let chaliarData = {}
        if ("deliveryID" in data) {


            const chaliarRef = doc(db, "users", data.deliveryID);
            const chaliarSnap = await getDoc(chaliarRef);
            chaliarData = chaliarSnap.data()
        } else {
            chaliarData = {}

        }



        const fullCommandData = {
            order: data,
            orderChaliar: { ...chaliarData, id: ("deliveryID" in data) ? data.deliveryID : null },
            orderUser: { ...userData, id: ("customerID" in data) ? data.customerID : null }
        }
        setDataCommandModal(fullCommandData)
        console.log(fullCommandData)
        setOpenCommandModal(true)
    };

    const handleCloseCommandModal = () => {
        setOpenCommandModal(false);
    };


    return (
        <div className={classes.tableResponsive}>

            <Dialog
                fullWidth
                open={openChaliarModal}
                onClose={handleCloseChaliarModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Chaliar
                </DialogTitle>
                <DialogContent>
                    <ChaliarModal userData={dataChaliarModal}></ChaliarModal>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseChaliarModal}>Fermer</Button>
                </DialogActions>
            </Dialog>


            <Dialog
                fullWidth
                open={openUserModal}
                onClose={handleCloseUserModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Utilisateur
                </DialogTitle>
                <DialogContent>
                    <UserModal userData={dataUserModal}></UserModal>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUserModal}>Fermer</Button>
                </DialogActions>
            </Dialog>




            <Dialog
                fullWidth
                open={openCommandModal}
                onClose={handleCloseCommandModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Commande
                </DialogTitle>
                <DialogContent>
                    <CommandeModal userData={dataCommandModal}></CommandeModal>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCommandModal}>Fermer</Button>
                </DialogActions>
            </Dialog>



            <Table className={classes.table}>
                {tableHead !== undefined ? (
                    <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                        <TableRow className={classes.tableHeadRow}>
                            {tableHead.map((prop, key) => {
                                return (
                                    <TableCell
                                        className={classes.tableCell + " " + classes.tableHeadCell}
                                        key={key}
                                    >
                                        {prop}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                ) : null}
                <TableBody>
                    {tableData.map((data, index) => {
                        return (<TableRow key={index} className={classes.tableBodyRow}>

                            {
                                Object.keys(data).map(function (pro) {
                                    if (pro == "status") {
                                        let str = "";
                                        switch (data[pro]) {
                                            case "scheduled":
                                                return (<TableCell key={pro} className={classes.tableCell}>
                                                    Prévue
                                                </TableCell>)
                                            case 'pickup':
                                                return (<TableCell key={pro} className={classes.tableCell}>
                                                    Récupération
                                                </TableCell>)
                                            case 'delivery':
                                                return (<TableCell key={pro} className={classes.tableCell}>
                                                    Livraison
                                                </TableCell>)
                                            case 'open':
                                                return (<TableCell key={pro} className={classes.tableCell}>
                                                    En recherche
                                                </TableCell>)
                                        }

                                    }
                                })
                            }

                            {
                                Object.keys(data).map(function (pro) {
                                    if (pro == "customerID") {
                                        return (<TableCell key={pro} className={classes.tableCellClick} onClick={() => testUser(data.customerID)}>
                                            {data[pro]}
                                        </TableCell>)
                                    }
                                })
                            }
                            {
                                Object.keys(data).map(function (pro) {
                                    if (pro == "status" && data.status == "open") {
                                        return (<TableCell key={pro} className={classes.tableCell}>

                                        </TableCell>)
                                    }
                                    else if (pro == "deliveryID") {
                                        return (<TableCell key={pro} className={classes.tableCellClick} onClick={() => testChaliar(data.deliveryID)}>
                                            {data[pro]}
                                        </TableCell>)
                                    }
                                })
                            }
                            {
                                Object.keys(data).map(function (pro) {
                                    if (pro == "price") {
                                        return (<TableCell key={pro} className={classes.tableCell}>
                                            {data[pro] / 100} €
                                        </TableCell>)
                                    }
                                })
                            }
                            <TableCell key="city" className={classes.tableCell}>
                                {data.adress.split(',')[1]}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Détail"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <IconButton
                                        onClick={() => testCommand(data)}
                                        aria-label="Détail"
                                        className={classes.tableActionButton}
                                    >
                                        <Visibility
                                            className={
                                                classes.tableActionButtonIcon +
                                                " " +
                                                classes.arrowForward
                                            }
                                        />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Suivre"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <IconButton
                                        onClick={follow(index)}
                                        aria-label="Suivre"
                                        className={classes.tableActionButton}
                                    >
                                        <ArrowForward
                                            className={
                                                classes.tableActionButtonIcon +
                                                " " +
                                                classes.arrowForward
                                            }
                                        />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            {/* <TableCell className={classes.tableCell}>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Suivre"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <IconButton
                                        aria-label="Suivre"
                                        className={classes.tableActionButton}
                                    >
                                        <ArrowForward
                                            className={
                                                classes.tableActionButtonIcon +
                                                " " +
                                                classes.arrowForward
                                            }
                                        />
                                    </IconButton>
                                </Tooltip>
                            </TableCell> */}

                        </TableRow>)
                    })}

                </TableBody>
            </Table>
        </div>
    );
}

CoursesTable.defaultProps = {
    tableHeaderColor: "gray",
};

CoursesTable.propTypes = {
    tableHeaderColor: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info",
        "rose",
        "gray",
    ]),
    tableHead: PropTypes.arrayOf(PropTypes.string),
    tableData: PropTypes.any,
    tableType: PropTypes.string,
};
