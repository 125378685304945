import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Container from "@material-ui/core/Container";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Visibility from "@material-ui/icons/Visibility";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import { httpsCallable } from 'firebase/functions';
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { onSnapshot, collection, where, getDoc, doc } from "firebase/firestore";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Slider from "@material-ui/core/Slider";

import logo from "assets/img/anonymous.png";

import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Collapse from "@material-ui/core/Collapse";

import { db, storage, functions, auth } from "firebase.jsx"; // course
import { ref, getDownloadURL, getMetadata, listAll, getBlob, deleteObject } from "firebase/storage";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import * as mime from 'react-native-mime-types';

import CommandeModal from "components/Fiches/Commande.jsx";

const useStyles = makeStyles(styles);
export default function ChaliarModal(props) {
    const classes = useStyles();
    const { userData } = props;

    const [urlImg, setUrlImg] = React.useState("anonymous");

    const [orderLive, setOrderLive] = React.useState(false);
    const [orderDone, setOrderDone] = React.useState(false);
    const [ticketLive, setTicketLive] = React.useState(false);
    const [ticketDone, setTicketDone] = React.useState(false);


    const [sent, setSent] = React.useState(0);
    const [owned, setOwned] = React.useState(0);
    const [requested, setRequested] = React.useState(0);


    useEffect(async () => {
        const docRef = doc(db, "stripe_chaliar", userData.id);
        const docSnap = await getDoc(docRef);
        const data = docSnap.data()
        setSent(docSnap.data().sent / 100)
        setOwned(docSnap.data().owned / 100)
        setRequested(docSnap.data().requested / 100)
    });

    useEffect(async () => {
        let _store = ref(storage, `users/${userData.id}`)
        try {
            const url = await getDownloadURL(_store)
            setUrlImg(url)
        } catch (error) {
            setUrlImg("anonymous")
        }
    });



    const handleClick = async (event) => {
        event.preventDefault()
        const idRef = ref(storage, userData.id);
        const folder = await listAll(idRef);
        const zip = new JSZip();
        for (const file of folder.items) {
            const fileRef = ref(storage, file.fullPath);
            const test = await getMetadata(fileRef)
            const fileBlob = await getBlob(fileRef)
            zip.file(`${file.fullPath}.${mime.extension(test.contentType)}`, fileBlob);
        }
        const blob = await zip.generateAsync({ type: "blob" });
        saveAs(blob, `${userData.id}.zip`);
    }


    const renderCurrentSelection = () => {
        switch (userData.vehicule) {
            case 1:
                return <p className={classes.testRight}>Vélo</p>;
            case 2:
                return <p className={classes.testRight}>Scooter</p>;
            case 3:
                return <p className={classes.testRight}>Voiture</p>;
            case 4:
                return <p className={classes.testRight}>Camion</p>;
            default:
                return <p className={classes.testRight}>Aucun type de véhicule séléctionné</p>;
        }
    };

    const [openUserModal, setOpenUserModal] = React.useState(false);
    const [dataUserModal, setDataUserModal] = React.useState({});
    const test = async (id) => {

        const orderRef = doc(db, "orders", id);
        const orderSnap = await getDoc(orderRef)
        const _orderData = orderSnap.data()
        const orderData = { ..._orderData, id: id }

        const userRef = doc(db, "users", orderData.customerID);
        const userSnap = await getDoc(userRef);
        const userData = userSnap.data()

        const chaliarRef = doc(db, "users", orderData.deliveryID);
        const chaliarSnap = await getDoc(chaliarRef);
        const chaliarData = chaliarSnap.data()

        const fullCommandData = {
            order: orderData,
            orderChaliar: { ...chaliarData, id: orderData.deliveryID },
            orderUser: { ...userData, id: orderData.customerID }
        }

        setDataUserModal(fullCommandData)
        setOpenUserModal(true)
    };
    const handleCloseUserModal = () => {
        setDataUserModal({})
        setOpenUserModal(false);
    };

    return (
        <div>
            {(openUserModal) ?

                <Dialog
                    fullWidth
                    open={openUserModal}
                    onClose={handleCloseUserModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {dataUserModal.order.id}
                    </DialogTitle>
                    <DialogContent>
                        <CommandeModal userData={dataUserModal}></CommandeModal>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseUserModal}>Fermer</Button>
                    </DialogActions>
                </Dialog> : null
            }
            <Grid container spacing={2} direction="column"

            >
                <Grid item xs={12} >
                    <div className={classes.logoImage}>
                        <img src={(urlImg != "anonymous") ? urlImg : logo} alt="logo" className={classes.img} />
                    </div>
                </Grid>
                <Grid item xs={12} >
                    <Card>
                        <CardHeader
                            title="INFORMATIONS"
                        >
                        </CardHeader>
                        <CardContent>
                            <p className={classes.testLeft}>Solde possédé</p>
                            <p className={classes.testRight}>{owned.toFixed(2)} €</p>
                            <p className={classes.testLeft}>Solde retiré</p>
                            <p className={classes.testRight}>{sent.toFixed(2)} €</p>
                            <p className={classes.testLeft}>Solde en retrait</p>
                            <p className={classes.testRight}>{requested.toFixed(2)} €</p>
                            <p className={classes.testLeft}>Véhicule</p>
                            {renderCurrentSelection()}
                            <p className={classes.testLeft}>E-mail</p>
                            <p className={classes.testRight}>{userData.email}</p>
                            <p className={classes.testLeft}>Adresse</p>
                            <p className={classes.testRight}>{userData.adress}</p>
                            <p className={classes.testRight}>{userData.zipCode}, {userData.city}</p>
                            <p className={classes.testLeft}>Prénom</p>
                            <p className={classes.testRight}>{userData.firstName}</p>
                            <p className={classes.testLeft}>Nom</p>
                            <p className={classes.testRight}>{userData.lastName}</p>
                            <p className={classes.testLeft}>Téléphone</p>
                            <p className={classes.testRight}>{userData.phone}</p>

                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} >
                    <Card>
                        <CardHeader
                            title="Courses en cours"
                            action={
                                <IconButton
                                    onClick={() => setOrderLive(!orderLive)}
                                    aria-label="expand"
                                >
                                    {orderLive ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            }
                        >
                        </CardHeader>
                        <div>
                            <Collapse in={orderLive} timeout="auto" unmountOnExit>
                                {
                                    (userData.inprogress.length > 0) ? <CardContent>
                                        {userData.inprogress.map((data, index) => {
                                            console.log("data", data)
                                            return (
                                                <CardHeader
                                                    key={index}
                                                    title={data.id}
                                                    onClick={() => test(data.id)}
                                                    action={
                                                        <IconButton
                                                            aria-label="expand"
                                                        >
                                                            <KeyboardArrowRight />
                                                        </IconButton>
                                                    }
                                                >
                                                </CardHeader>
                                            )
                                        })}
                                    </CardContent> : null
                                }

                            </Collapse>
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            title="Courses terminées"
                            action={
                                <IconButton
                                    onClick={() => setOrderDone(!orderDone)}
                                    aria-label="expand"
                                >
                                    {orderDone ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            }
                        >
                        </CardHeader>
                        <div>
                            <Collapse in={orderDone} timeout="auto" unmountOnExit>
                                {
                                    (userData.done.length > 0) ? <CardContent>
                                        {userData.done.map((data, index) => {
                                            console.log("data", data)
                                            return (
                                                <CardHeader
                                                    key={index}
                                                    title={data.id}
                                                    onClick={() => test(data.id)}
                                                    action={
                                                        <IconButton
                                                            aria-label="expand"
                                                        >
                                                            <KeyboardArrowRight />
                                                        </IconButton>
                                                    }
                                                >
                                                </CardHeader>
                                            )
                                        })}
                                    </CardContent> : null
                                }

                            </Collapse>
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            title="Signalements en cours"
                            action={
                                <IconButton
                                    onClick={() => setTicketLive(!ticketLive)}
                                    aria-label="expand"
                                >
                                    {ticketLive ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            }
                        >
                        </CardHeader>
                        <div>
                            <Collapse in={ticketLive} timeout="auto" unmountOnExit>
                            </Collapse>
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            title="Signalements terminés"
                            action={
                                <IconButton
                                    onClick={() => setTicketDone(!ticketDone)}
                                    aria-label="expand"
                                >
                                    {ticketDone ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            }
                        >
                        </CardHeader>
                        <div>
                            <Collapse in={ticketDone} timeout="auto" unmountOnExit>
                            </Collapse>
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={handleClick}>Télécharger les fichiers</Button>
                </Grid>
            </Grid>
        </div >
    );
}


ChaliarModal.propTypes = {
    userData: PropTypes.any,
};
