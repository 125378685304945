import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ShopTable from "components/Table/Shop.jsx";
import { onSnapshot, collection, where } from "firebase/firestore";
import { db } from "firebase.jsx"; // add

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function Shop() {

  const [sent, setSent] = useState([]);
  const [toSend, setTosend] = useState([]);

  useEffect(() =>
    onSnapshot(collection(db, "shop"), (snapshot) => {
      const _sent = [];
      const _toSend = [];
      snapshot.forEach((doc) => {
        const _data = doc.data();
        console.log("_data", _data.sent)
        if (_data.sent) {
          _sent.push({ ..._data, id: doc.id });
        } else {
          _toSend.push({ ..._data, id: doc.id });
        }
      });

      setSent(_sent);
      setTosend(_toSend);
      console.log("_sent",_toSend)
    }
    ),
    []
  );

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>A Traiter</h4>
            <p className={classes.cardCategoryWhite}>Achats sur le shop à expedier</p>
          </CardHeader>
          <CardBody>
            <ShopTable
              tableType="live"
              tableHeaderColor="warning"
              tableHead={[
                "Objets",
                "Adresse",
                "Destinataire",
                "Informations",
                "Action",
              ]}
              tableData={toSend}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="success">
            <h4 className={classes.cardTitleWhite}>Expédié</h4>
            <p className={classes.cardCategoryWhite}>Historique des achats sur le Shop</p>
          </CardHeader>
          <CardBody>
            <ShopTable
              tableType="live"
              tableHeaderColor="warning"
              tableHead={[
                "Objets",
                "Adresse",
                "Ville",
                "Code Postal",
              ]}
              tableData={sent}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
