import React, { useState, useEffect } from "react";

import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import { NavLink, useLocation } from "react-router-dom";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Info from "@material-ui/icons/Info";
// import Dashboard from "@material-ui/icons/Dashboard";
// import Search from "@material-ui/icons/Search";
// core components
// import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.jsx";

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";

import { onSnapshot, collection, where } from "firebase/firestore";
import { db } from "firebase.jsx";
import { useAuth } from "../../contexts/AuthContext"

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks() {
  const classes = useStyles();
  const { logout } = useAuth()
  const [openInfo, setOpenInfo] = React.useState(null);
  const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);

  const handleClickInfo = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenInfo(null);
    } else {
      setOpenInfo(event.currentTarget);
    }
  };
  const handleCloseInfo = () => {
    setOpenInfo(null);
  };

  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };



  const [payout, setPayout] = useState();
  const [chaliar, setChaliar] = useState();
  const [live, setLive] = useState();
  useEffect(() =>
    onSnapshot(collection(db, "stripe_chaliar"), (snapshot) => {
      let nb = 0;
      snapshot.forEach((doc) => {
        const _data = doc.data();
        if (_data.requested > 0) {
          nb++;
        }
      });
      setPayout(nb);
    }
    ),
    []
  );
  useEffect(() =>
    onSnapshot(collection(db, "request"), (snapshot) => {
      let nb = 0;
      snapshot.forEach((doc) => {
        nb++;
      });

      setChaliar(nb);
    }
    ),
    []
  );
  useEffect(() =>
    onSnapshot(collection(db, "orders"), (snapshot) => {
      let nb = 0
      snapshot.forEach((doc) => {
        const _data = doc.data();
        console.log("STATUS", _data.status)
        if (_data.status != "cancelled" && _data.status != "done")
          nb += 1
      });
      setLive(nb)

    }
    ),
    []
  );
  return (
    <div>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openInfo ? "notification-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickInfo}
          className={classes.buttonLink}
        >
          <Info className={classes.icons} />

          {(payout > 0 || chaliar > 0) ? (
            <span className={classes.notifications}>{chaliar + payout}</span>
          ) : null}
          <Hidden mdUp implementation="css">
            <p onClick={handleCloseInfo} className={classes.linkText}>
              Notification
            </p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openInfo)}
          anchorEl={openInfo}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openInfo }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseInfo}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleCloseInfo}
                      className={classes.dropdownItem}
                    >
                      Il y a 0 chaliar(s) en ligne
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseInfo}
                      className={classes.dropdownItem}
                    >
                      Il y a 0 utilisateurs(s) en ligne
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseInfo}
                      className={classes.dropdownItem}
                    >
                      Il y a {live} course(s) en cours
                    </MenuItem>




                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>



































      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openNotification ? "notification-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={classes.buttonLink}
        >
          <Notifications className={classes.icons} />

          {(payout > 0 || chaliar > 0) ? (
            <span className={classes.notifications}>{chaliar + payout}</span>
          ) : null}
          <Hidden mdUp implementation="css">
            <p onClick={handleCloseNotification} className={classes.linkText}>
              Notification
            </p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openNotification }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    {chaliar > 0 ? (
                      <NavLink
                        to="/admin/newuser"
                        activeClassName="active"
                        key="01">
                        <MenuItem
                          onClick={handleCloseNotification}
                          className={classes.dropdownItem}
                        >
                          Il reste {chaliar} Chaliar à confirmer
                        </MenuItem>
                      </NavLink>
                    ) : null}

                    {payout > 0 ? (
                      <NavLink
                        to="/admin/payout"
                        activeClassName="active"
                        key="02">
                        <MenuItem
                          onClick={handleCloseNotification}
                          className={classes.dropdownItem}
                        >
                          Vous avez {payout} demande de payout
                        </MenuItem>
                      </NavLink>
                    ) : null}
                    {(payout < 1 && chaliar < 1) ? (
                      <MenuItem
                        onClick={handleCloseNotification}
                        className={classes.dropdownItem}
                      >
                        Vous n'avez aucune demande à traiter
                      </MenuItem>
                    ) : null}




                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Person className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={logout}
                      className={classes.dropdownItem}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
}
