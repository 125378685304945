import React from "react";
import { useLocation } from 'react-router-dom';
import * as qs from 'qs'

import { db } from "firebase.jsx";
import { doc, getDoc } from "firebase/firestore";
import chaliarRider from "assets/img/rider.svg";
import pickup from "assets/img/pickup.png";
import delivery from "assets/img/delivery.png";
import { onSnapshot, collection, where, query, getDocs } from "firebase/firestore";
import UserModal from "components/Fiches/User.jsx";
import ChaliarModal from "components/Fiches/Chaliar.jsx";
import CommandeModal from "components/Fiches/Commande.jsx";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import randomcolor from 'randomcolor'

export default function Maps() {
  const mapRef = React.useRef(null);
  const location = useLocation();


  const [showChaliar, setShowChaliar] = React.useState(true);
  const [id, setHasID] = React.useState(false);

  const [showCourses, setShowCourses] = React.useState(true);

  React.useEffect(async () => {
    console.log("ahah")
    let _id = qs.parse(location.search, { ignoreQueryPrefix: true }).id
    if (_id) {
      setHasID(true)
      const docRef = doc(db, "orders", _id);
      const docSnap = await getDoc(docRef);
      console.log("Document data:", docSnap.data());
      var data = docSnap.data();
      console.log("DATA")
      console.log(data)
    } else {
      var scheduled = []
      var inprogress = []


      var live = []
      const qLive = query(collection(db, "orders"), where("status", 'in', ['done', 'cancelle']),);
      const querySnapshotLive = await getDocs(qLive);
      querySnapshotLive.forEach((doc) => {
        const _data = doc.data();
        live.push({ ..._data, id: doc.id })
      });

      var chaliar = []
      const qChaliar = query(collection(db, "users"), where("isChaliar", "==", true), where("okChaliar", "==", true));
      const querySnapshotChaliar = await getDocs(qChaliar);
      querySnapshotChaliar.forEach((doc) => {
        const _data = doc.data();
        chaliar.push({ ..._data, id: doc.id })
      });
      console.log("CHALIARS:", chaliar)
      console.log("LIVE:", live)
    }




    let google = window.google;
    let map = mapRef.current;
    let lat = "48.8";
    let lng = "2.3";
    const myLatlng = new google.maps.LatLng(lat, lng);
    const mapOptions = {
      zoom: 6,
      center: myLatlng,
      zoomControl: true,
      styles: [
        {
          featureType: "water",
          stylers: [{ saturation: 43 }, { lightness: -11 }, { hue: "#0088ff" }],
        },
        {
          featureType: "road",
          elementType: "geometry.fill",
          stylers: [
            { hue: "#ff0000" },
            { saturation: -100 },
            { lightness: 99 },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [{ color: "#808080" }, { lightness: 54 }],
        },
        {
          featureType: "landscape.man_made",
          elementType: "geometry.fill",
          stylers: [{ color: "#ece2d9" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry.fill",
          stylers: [{ color: "#ccdca1" }],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [{ color: "#767676" }],
        },
        {
          featureType: "road",
          elementType: "labels.text.stroke",
          stylers: [{ color: "#ffffff" }],
        },
        { featureType: "poi", stylers: [{ visibility: "off" }] },
        {
          featureType: "landscape.natural",
          elementType: "geometry.fill",
          stylers: [{ visibility: "on" }, { color: "#b8cb93" }],
        },
        { featureType: "poi.park", stylers: [{ visibility: "on" }] },
        {
          featureType: "poi.sports_complex",
          stylers: [{ visibility: "on" }],
        },
        { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
        {
          featureType: "poi.business",
          stylers: [{ visibility: "simplified" }],
        },
      ],
    };

    map = new google.maps.Map(map, mapOptions);

    if (showChaliar == true && !_id) {
      chaliar.forEach((single) => {
        var image = {
          url: chaliarRider,
          size: new google.maps.Size(100, 100),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(50, 50)
        };
        const charMarker = new google.maps.Marker({
          position: new google.maps.LatLng(single.pos._lat, single.pos._long),
          map: map,
          clickable: true,
          icon: image,
          title: single.id
        });
        charMarker.addListener("click", (event) => {
          testChaliar(charMarker.title)
        });
      })
    }

    if (showCourses == true && !_id) {
      
      live.forEach((doc, index) => {
        var pickupImage = {
          url: pickup,
          size: new google.maps.Size(100, 100),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(50, 50)
        };
        const charMarkerPickUp = new google.maps.Marker({
          position: new google.maps.LatLng(doc.pickUp._lat, doc.pickUp._long),
          map: map,
          icon: pickupImage,
          clickable: true,
          title: doc.id
        });
        charMarkerPickUp.addListener("click", (event) => {
          testCommand(doc)
        });
        var deliveryImage = {
          url: delivery,
          size: new google.maps.Size(100, 100),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(50, 50)
        };
        const charMarkerDestination = new google.maps.Marker({
          position: new google.maps.LatLng(doc.destination._lat, doc.destination._long),
          map: map,
          icon: deliveryImage,
          clickable: true,
          title: doc.id
        });
        charMarkerDestination.addListener("click", (event) => {
          testCommand(doc)
        });

        const directionsService = new google.maps.DirectionsService();
        directionsService
          .route({
            origin: { lat: doc.pickUp._lat, lng: doc.pickUp._long },
            destination: { lat: doc.destination._lat, lng: doc.destination._long },
            travelMode: google.maps.TravelMode.DRIVING,
          })
          .then((response) => {
            console.log(response.routes[0].overview_path)
            if (response.routes.length > 0) {
              const polylineRoute = new google.maps.Polyline({
                path: response.routes[0].overview_path,
                strokeColor: randomcolor(),
                map: map,
                clickable: true,
                title: doc.id
              });
              polylineRoute.addListener("click", (event) => {
                testCommand(doc)
              });
            }
          })
          .catch((e) => window.alert("Impossible de créer un chemin entre le colis et la destination " + status));
      })
    }





    if (_id) {
      if (data.position) {
        var image = {
          url: chaliarRider,
          size: new google.maps.Size(100, 100),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(50, 50)
        };
        const position = new google.maps.Marker({
          position: new google.maps.LatLng(data.position._lat, data.position._long),
          map: map,
          icon: image,
          animation: google.maps.Animation.DROP,
          title: "Liste les tickets en cours",
        });
      }
      const directionsService = new google.maps.DirectionsService();
      const directionsRenderer = new google.maps.DirectionsRenderer();
      directionsRenderer.setMap(map);
      directionsService
        .route({
          origin: { lat: data.pickUp._lat, lng: data.pickUp._long },
          destination: { lat: data.destination._lat, lng: data.destination._long },
          travelMode: google.maps.TravelMode.DRIVING,
        })
        .then((response) => {
          directionsRenderer.setDirections(response);
        })
        .catch((e) => window.alert("Impossible de créer un chemin entre le colis et la destination " + status));

    }

  }, [showChaliar, showCourses]);






  const [openChaliarModal, setOpenChaliarModal] = React.useState(false);
  const [dataChaliarModal, setDataChaliarModal] = React.useState({});


  const testChaliar = async (id) => {
    if (id) {
      console.log("CHALIAR ID", id);
      const docRef2 = doc(db, "users", id);
      const docSnap2 = await getDoc(docRef2);
      const _data2 = docSnap2.data()
      console.log("CHALIAR DATA", _data2);
      setDataChaliarModal({ ..._data2, id: id })
      setOpenChaliarModal(true)
    }
  };

  const handleCloseChaliarModal = () => {
    setOpenChaliarModal(false);
  };









  const [openCommandModal, setOpenCommandModal] = React.useState(false);
  const [dataCommandModal, setDataCommandModal] = React.useState({});

  const testCommand = async (data) => {
    let userData = {}
    if ("customerID" in data) {

      const userRef = doc(db, "users", data.customerID);
      const userSnap = await getDoc(userRef);
      userData = userSnap.data()
    } else {
      userData = {}

    }


    let chaliarData = {}
    if ("deliveryID" in data) {


      const chaliarRef = doc(db, "users", data.deliveryID);
      const chaliarSnap = await getDoc(chaliarRef);
      chaliarData = chaliarSnap.data()
    } else {
      chaliarData = {}

    }



    const fullCommandData = {
      order: data,
      orderChaliar: { ...chaliarData, id: ("deliveryID" in data) ? data.deliveryID : null },
      orderUser: { ...userData, id: ("customerID" in data) ? data.customerID : null }
    }
    setDataCommandModal(fullCommandData)
    console.log(fullCommandData)
    setOpenCommandModal(true)
  };
  const handleCloseCommandModal = () => {
    setOpenCommandModal(false);
  };


  return (
    <>
      <Dialog
        fullWidth
        open={openChaliarModal}
        onClose={handleCloseChaliarModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Chaliar
        </DialogTitle>
        <DialogContent>
          <ChaliarModal userData={dataChaliarModal}></ChaliarModal>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseChaliarModal}>Fermer</Button>
        </DialogActions>
      </Dialog>







      <Dialog
        fullWidth
        open={openCommandModal}
        onClose={handleCloseCommandModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Commande
        </DialogTitle>
        <DialogContent>
          <CommandeModal userData={dataCommandModal}></CommandeModal>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCommandModal}>Fermer</Button>
        </DialogActions>
      </Dialog>


      <div style={{ height: `calc(100vh - 70px)` }} ref={mapRef}></div>
      {(!id) ? <div style={{ height: `75px`, position: 'absolute', width: '200px', bottom: '10px', left: '10px', backgroundColor: 'white' }}>
        <FormGroup style={{ marginLeft: '15px' }}>
          <FormControlLabel control={<Checkbox size="small" checked={showChaliar} onChange={() => { setShowChaliar(!showChaliar) }} />} label="Chaliars" />
          <FormControlLabel control={<Checkbox size="small" checked={showCourses} onChange={() => { setShowCourses(!showCourses) }} />} label="Courses" />
        </FormGroup>
      </div> : null}
    </>
  );
};
