import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ArrowForward from "@material-ui/icons/ArrowForward";
import { db, storage, functions } from "firebase.jsx"; // add
import { httpsCallable } from 'firebase/functions';
import { onSnapshot, collection, where, getDoc, doc } from "firebase/firestore";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

import ChaliarModal from "components/Fiches/Chaliar.jsx";

import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles(styles);

export default function PayOutTable(props) {
    const classes = useStyles();
    const { tableHead, tableData, tableHeaderColor, tableType } = props;


    const notify = () => toast('Tentative de PayOut', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    const notify2 = () => toast.success('Payout Effectué', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });


    const [open, setOpen] = React.useState(false);
    const [rejectID, setRejectID] = React.useState();
    const [rejectRaison, setRejectRaison] = React.useState();
    const handleClickOpen = (parameter) => (event) => {
        event.preventDefault()
        setRejectID(tableData[parameter].id)
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    };
    const rejectChange = (event) => {
        setRejectRaison(event.target.value)
    };
    const handleCloseOk = () => {
        setOpen(false);
        console.log(rejectID)
        console.log(rejectRaison)

        const reject = httpsCallable(functions, 'rejectPayout');
        reject({ id: rejectID, raison: rejectRaison })
            .then((result) => {
                console.log(result);
            }).catch((error) => {
                console.log(error);
            });
        setRejectID("")

    };



    const [openValide, setOpenValide] = React.useState(false);
    const [acceptID, setAcceptID] = React.useState();
    const handleClickOpenValide = (parameter) => (event) => {
        setAcceptID(tableData[parameter].id)
        console.log("acceptID", tableData[parameter].id);
        setOpenValide(true);
    };
    const handleCloseValide = () => {
        notify()
        setOpenValide(false);
        const reject = httpsCallable(functions, 'acceptPayout');
        reject({ id: acceptID })
            .then((result) => {
                notify2()
                console.log(result);
            }).catch((error) => {
                console.log(error);
            });
        setAcceptID("")
    };

    const handleCloseValideNo = () => {
        setOpenValide(false);
        setAcceptID("")
    };



    const [openUserModal, setOpenUserModal] = React.useState(false);
    const [dataUserModal, setDataUserModal] = React.useState({});

    const test = async (data) => {
        console.log("ID TEST AHA", data);
        const docRef = doc(db, "users", data.id);
        const docSnap = await getDoc(docRef);
        const _data = docSnap.data()
        console.log("DATA DATA", _data);
        setDataUserModal({ ..._data, id: data.id })
        setOpenUserModal(true)
    };

    const handleCloseUserModal = () => {
        setOpenUserModal(false);
    };


    return (
        <div className={classes.tableResponsive}>



            <Dialog
                fullWidth
                open={openUserModal}
                onClose={handleCloseUserModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Chaliar
                </DialogTitle>
                <DialogContent>
                    <ChaliarModal userData={dataUserModal}></ChaliarModal>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUserModal}>Fermer</Button>
                </DialogActions>
            </Dialog>






            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Refuser le PayOut</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Vous êtes sur le point de refuser de payer votre Chaliar, veuillez renseigner une raison.
                    </DialogContentText>
                    <TextField
                        onChange={rejectChange}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Raison"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Annuler</Button>
                    <Button onClick={handleCloseOk}>Valider</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openValide} onClose={handleCloseValideNo}>
                <DialogTitle>Accepter le PayOut</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Vous êtes sur le point d'effectuer un paiement, veuillez confirmer.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseValideNo}>Annuler</Button>
                    <Button onClick={handleCloseValide}>Confirmer</Button>
                </DialogActions>
            </Dialog>
            <Table className={classes.table}>
                {tableHead !== undefined ? (
                    <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                        <TableRow className={classes.tableHeadRow}>
                            {tableHead.map((prop, key) => {
                                return (
                                    <TableCell
                                        className={classes.tableCell + " " + classes.tableHeadCell}
                                        key={key}
                                    >
                                        {prop}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                ) : null}
                <TableBody>
                    {tableData.map((data, index) => {
                        return (<TableRow key={index} className={classes.tableBodyRow}>
                            {
                                Object.keys(data).map(function (pro) {
                                    console.log(tableData)
                                    if (pro == "id") {
                                        return (<TableCell key={pro} className={classes.tableCellClick} onClick={() => test(data)}>
                                            {data[pro]}
                                        </TableCell>)
                                    }
                                })
                            }
                            {
                                Object.keys(data).map(function (pro) {
                                    if (pro == "account_id") {
                                        return (<TableCell key={pro} className={classes.tableCell}>
                                            {data[pro]}
                                        </TableCell>)
                                    }
                                })
                            }
                            {
                                Object.keys(data).map(function (pro) {
                                    if (pro == "requested") {
                                        return (<TableCell key={pro} className={classes.tableCell}>
                                            {data[pro] / 100} €
                                        </TableCell>)
                                    }
                                })
                            }
                            <TableCell className={classes.tableCell}>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Accepter"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <IconButton
                                        onClick={handleClickOpenValide(index)}
                                        aria-label="Accepter"
                                        className={classes.tableActionButton}
                                    >
                                        <Check
                                            className={
                                                classes.tableActionButtonIcon +
                                                " " +
                                                classes.arrowForward
                                            }
                                        />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Refuser"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <IconButton
                                        onClick={handleClickOpen(index)}
                                        aria-label="Refuser"
                                        className={classes.tableActionButton}
                                    >
                                        <Close
                                            className={
                                                classes.tableActionButtonIcon +
                                                " " +
                                                classes.arrowForward
                                            }
                                        />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>

                        </TableRow>)
                    })}

                </TableBody>
            </Table>
        </div>
    );
}

PayOutTable.defaultProps = {
    tableHeaderColor: "gray",
};

PayOutTable.propTypes = {
    tableHeaderColor: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info",
        "rose",
        "gray",
    ]),
    tableHead: PropTypes.arrayOf(PropTypes.string),
    tableData: PropTypes.any,
    tableType: PropTypes.string,
};
