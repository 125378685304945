/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Help from "@material-ui/icons/Help";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Person from "@material-ui/icons/Person";
import Description from "@material-ui/icons/Description";
import DirectionsBike from "@material-ui/icons/DirectionsBike";
import Payment from "@material-ui/icons/Payment";
import SettingsApplications from "@material-ui/icons/SettingsApplications";
import LocationOn from "@material-ui/icons/LocationOn";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import Tickets from "views/TableList/tickets";
import Shop from "views/TableList/shop";
import Chaliar from "views/TableList/chaliar";
import FaQ from "views/TableList/faq";
import Users from "views/TableList/users";
import NewUser from "views/TableList/newUser";
import Options from "views/TableList/options";
import PayOut from "views/TableList/payOut";
import Maps from "views/Maps/Maps.jsx";
import Login from "views/Login";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Utilisateurs",
    icon: Person,
    component: Users,
    layout: "/admin",
  },
  {
    path: "/chaliar",
    name: "Chaliars",
    icon: DirectionsBike,
    component: Chaliar,
    layout: "/admin",
  },
  {
    path: "/tickets",
    name: "Commandes",
    icon: Description,
    component: Tickets,
    layout: "/admin",
  },
  {
    path: "/newuser",
    name: "Nouveaux Chaliars",
    icon: PersonAdd,
    component: NewUser,
    layout: "/admin",
  },
  {
    path: "/payout",
    name: "PayOut",
    icon: Payment,
    component: PayOut,
    layout: "/admin",
  },
  {
    path: "/shop",
    name: "Shop",
    icon: Payment,
    component: Shop,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Paramétrage",
    icon: SettingsApplications,
    component: Options,
    layout: "/admin",
  },
  {
    path: "/faq",
    name: "FaQ",
    icon: Help,
    component: FaQ,
    layout: "/admin",
  },
  {
    path: "/map",
    name: "map",
    icon: LocationOn,
    component: Maps,
    layout: "/admin",
  },
];

export default dashboardRoutes;
