import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Visibility from "@material-ui/icons/Visibility";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import { httpsCallable } from 'firebase/functions';
import { functions } from "firebase.jsx"; // add
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { onSnapshot, collection, where, updateDoc, doc } from "firebase/firestore";
import { db } from "firebase.jsx";


import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Slider from "@material-ui/core/Slider";


import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SelectAllRounded } from "@material-ui/icons";
import { withStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(styles);
export default function CoursesTable(props) {
    const classes = useStyles();
    const { tableHead, tableData, tableHeaderColor, tableType } = props;


    const [open, setOpen] = React.useState(false);
    const [openView, setOpenView] = React.useState(false);
    const [data, setData] = React.useState({});
    const [dataView, setDataView] = React.useState({});
    const [form, setValue] = React.useState({});
    const view = (parameter) => (event) => {
        event.preventDefault()
        setData(tableData[parameter])
        setOpen(true);
    }
    const viewview = (parameter) => (event) => {
        event.preventDefault()
        const _data = JSON.parse(tableData[parameter].data)
        setDataView(_data)
        setOpenView(true);
    }

    const handleCloseView = () => {
        setOpenView(false);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseOk = () => {
        updateDoc(doc(db, "shop", data.id), {
            "sent": true,
        });
        setOpen(false);
    };

    const handleClick = (index) => (event) => {
        console.log(index);
        console.log(tableData[index].id);
        const deleteUser = httpsCallable(functions, 'removeUser');
        deleteUser({ id: tableData[index].id, type: "users" })
            .then((result) => {
                toast.success('Utilisateur supprimé', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }).catch((error) => {
                console.log(error)
                toast.error('Erreur lors de la suppression', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

    }

    return (
        <div className={classes.tableResponsive}>
            <ToastContainer
                position="bottom-right"
                autoClose={false}
                hideProgressBar={true}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Dialog
                fullWidth
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {data.id}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Attention, vous êtes sur le point de confirmer l'envoie de la commande
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                    <Button onClick={handleCloseOk}>Confirmer</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth
                open={openView}
                onClose={handleCloseView}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {data.id}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {Object.keys(dataView).map(key => {
                            console.log(key); // 👉️ name, country
                            console.log(dataView[key]); // 👉️ James, Chile
                            let button = ""
                            if (dataView[key].size) {
                                button = `   (Taille ${dataView[key].size})`;
                            }
                            return (<div key={key}>
                                {dataView[key].name} {button}
                            </div>)
                        })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseView}>Fermer</Button>
                </DialogActions>
            </Dialog>
            <Table className={classes.table}>
                {tableHead !== undefined ? (
                    <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                        <TableRow className={classes.tableHeadRow}>
                            {tableHead.map((prop, key) => {
                                return (
                                    <TableCell
                                        className={classes.tableCell + " " + classes.tableHeadCell}
                                        key={key}
                                    >
                                        {prop}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                ) : null}
                <TableBody>
                    {tableData.map((data, index) => {
                        if (data.sent) {
                            return (
                                <TableRow key={index} className={classes.tableBodyRow}>

                                    <TableCell className={classes.tableCell}>
                                        <Tooltip
                                            id="tooltip-top"
                                            title="Visualiser la commande"
                                            placement="top"
                                            classes={{ tooltip: classes.tooltip }}
                                        >
                                            <IconButton
                                                onClick={viewview(index)}
                                                aria-label="Envoyer"
                                                className={classes.tableActionButton}
                                            >
                                                <Visibility
                                                    className={
                                                        classes.tableActionButtonIcon +
                                                        " " +
                                                        classes.arrowForward
                                                    }
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                    {
                                        <TableCell key="adress" className={classes.tableCell}>
                                            {data["adress"]}
                                        </TableCell>
                                    }

                                    {

                                        <TableCell key="name" className={classes.tableCell}>
                                            {data["prenom"]}  {data["nom"]}
                                        </TableCell>
                                    }
                                    {
                                        <TableCell key="info" className={classes.tableCell}>
                                            {data["info"]}
                                        </TableCell>
                                    }


                                </TableRow>)
                        } else {
                            return (
                                <TableRow key={index} className={classes.tableBodyRow}>

                                    <TableCell className={classes.tableCell}>
                                        <Tooltip
                                            id="tooltip-top"
                                            title="Visualiser la commande"
                                            placement="top"
                                            classes={{ tooltip: classes.tooltip }}
                                        >
                                            <IconButton
                                                onClick={viewview(index)}
                                                aria-label="Envoyer"
                                                className={classes.tableActionButton}
                                            >
                                                <Visibility
                                                    className={
                                                        classes.tableActionButtonIcon +
                                                        " " +
                                                        classes.arrowForward
                                                    }
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                    {
                                        <TableCell key="adress" className={classes.tableCell}>
                                            {data["adress"]}
                                        </TableCell>
                                    }

                                    {

                                        <TableCell key="name" className={classes.tableCell}>
                                            {data["prenom"]}  {data["nom"]}
                                        </TableCell>
                                    }
                                    {
                                        <TableCell key="info" className={classes.tableCell}>
                                            {data["info"]}
                                        </TableCell>
                                    }

                                    <TableCell className={classes.tableCell}>
                                        <Tooltip
                                            id="tooltip-top"
                                            title="Confirmer l'expedition"
                                            placement="top"
                                            classes={{ tooltip: classes.tooltip }}
                                        >
                                            <IconButton
                                                onClick={view(index)}
                                                aria-label="Envoyer"
                                                className={classes.tableActionButton}
                                            >
                                                <Check
                                                    className={
                                                        classes.tableActionButtonIcon +
                                                        " " +
                                                        classes.arrowForward
                                                    }
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>


                                </TableRow>)
                        }
                    })}

                </TableBody>
            </Table>
        </div >
    );
}

CoursesTable.defaultProps = {
    tableHeaderColor: "gray",
};

CoursesTable.propTypes = {
    tableHeaderColor: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info",
        "rose",
        "gray",
    ]),
    tableHead: PropTypes.arrayOf(PropTypes.string),
    tableData: PropTypes.any,
    tableType: PropTypes.string,
};
