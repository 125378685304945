import React from "react";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { styled } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { onSnapshot, collection, where, updateDoc, doc } from "firebase/firestore";
import { db } from "firebase.jsx"; // add
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import axios from 'axios';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const data = "insufficient permissions";
export default function Options() {


  const section = {
    height: "35px",
    paddingTop: 5,
    backgroundColor: "#fff"
  };
  const [data, setData] = React.useState({});







  const [SB, setSB] = React.useState('');
  const changeSB = (event) => {
    setSB(event.target.value)
  };
  const [SD, setSD] = React.useState('');
  const changeSD = (event) => {
    setSD(event.target.value)
  };
  const [ST, setST] = React.useState('');
  const changeST = (event) => {
    setST(event.target.value)
  };


  const [MB, setMB] = React.useState('');
  const changeMB = (event) => {
    setMB(event.target.value)
  };
  const [MD, setMD] = React.useState('');
  const changeMD = (event) => {
    setMD(event.target.value)
  };
  const [MT, setMT] = React.useState('');
  const changeMT = (event) => {
    setMT(event.target.value)
  };


  const [LB, setLB] = React.useState('');
  const changeLB = (event) => {
    setLB(event.target.value)
  };
  const [LD, setLD] = React.useState('');
  const changeLD = (event) => {
    setLD(event.target.value)
  };
  const [LT, setLT] = React.useState('');
  const changeLT = (event) => {
    setLT(event.target.value)
  };


  const [XLB, setXLB] = React.useState('');
  const changeXLB = (event) => {
    setXLB(event.target.value)
  };
  const [XLD, setXLD] = React.useState('');
  const changeXLD = (event) => {
    setXLD(event.target.value)
  };
  const [XLT, setXLT] = React.useState('');
  const changeXLT = (event) => {
    setXLT(event.target.value)
  };

  const updateData = (event) => {
    setOpenUpdate(true)
  };




  const [openUpdate, setOpenUpdate] = React.useState(false);
  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };
  const handleCloseUpdateOk = () => {
    setOpenUpdate(false);
    updateDoc(doc(db, "multiplier", "S"), {
      "add": parseFloat(SB),
      "distanceMulti": parseFloat(SD),
      "timeMulti": parseFloat(ST),
    });
    updateDoc(doc(db, "multiplier", "M"), {
      "add": parseFloat(MB),
      "distanceMulti": parseFloat(MD),
      "timeMulti": parseFloat(MT),
    });
    updateDoc(doc(db, "multiplier", "L"), {
      "add": parseFloat(LB),
      "distanceMulti": parseFloat(LD),
      "timeMulti": parseFloat(LT),
    });
    updateDoc(doc(db, "multiplier", "XL"), {
      "add": parseFloat(XLB),
      "distanceMulti": parseFloat(XLD),
      "timeMulti": parseFloat(XLT),
    });
  };




  const [open, setOpen] = React.useState(false);
  const [respS, setRespS] = React.useState("");
  const [respM, setRespM] = React.useState("");
  const [respL, setRespL] = React.useState("");
  const [respXL, setRespXL] = React.useState("");
  const [X1, setX1] = React.useState(false);
  const [Y1, setY1] = React.useState(false);
  const [X2, setX2] = React.useState(false);
  const [Y2, setY2] = React.useState(false);
  const handleClickOpen = (parameter) => (event) => {
    event.preventDefault()
    setOpen(!open);
  }
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseOK = async () => {
    let tmp = value.label
    let tmp2 = value2.label
    let google = window.google;
    var origin1 = tmp
    var destinationB = tmp2
    var service = new google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [tmp],
        destinations: [tmp2],
        travelMode: 'BICYCLING',
      }, callback2);

    function callback2(response, status) {
      console.log(response);
      const distance = response.rows[0].elements[0].distance.value
      const duration = response.rows[0].elements[0].duration.value
      const S = SB + (distance * SD) + (duration * ST)
      setRespS(`Un colis S prendrait ${duration} secondes sur ${distance} m pour un coup total de ${S / 100}`)
    }
    service.getDistanceMatrix(
      {
        origins: [origin1],
        destinations: [destinationB],
        travelMode: 'DRIVING',
      }, callback);

    function callback(response, status) {
      const distance = response.rows[0].elements[0].distance.value
      const duration = response.rows[0].elements[0].duration.value
      const M = MB + (distance * MD) + (duration * MT)
      const L = LB + (distance * LD) + (duration * LT)
      const XL = XLB + (distance * XLD) + (duration * XLT)
      setRespM(`Un colis M prendrait ${duration} secondes sur ${distance} m pour un coup total de ${M / 100}`)
      setRespL(`Un colis L prendrait ${duration} secondes sur ${distance} m pour un coup total de ${L / 100}`)
      setRespXL(`Un colis XL prendrait ${duration} secondes sur ${distance} m pour un coup total de ${XL / 100}`)
    }

  };
  const handleChangeLatD = (event) => {
    setX1(event.target.value);
  };
  const handleChangeLonD = (event) => {
    setY1(event.target.value);
  };
  const handleChangeLatA = (event) => {
    setX2(event.target.value);
  };
  const handleChangeLonA = (event) => {
    setY2(event.target.value);
  };
  React.useEffect(() =>
    onSnapshot(collection(db, "multiplier"), (snapshot) => {
      const a = {};
      snapshot.forEach((doc) => {
        const _data = doc.data();
        switch (doc.id) {
          case 'S':
            setSB(_data.add)
            setSD(_data.distanceMulti)
            setST(_data.timeMulti)
            break;
          case 'M':
            setMB(_data.add)
            setMD(_data.distanceMulti)
            setMT(_data.timeMulti)
            break;
          case 'L':
            setLB(_data.add)
            setLD(_data.distanceMulti)
            setLT(_data.timeMulti)
            break;
          case 'XL':
            setXLB(_data.add)
            setXLD(_data.distanceMulti)
            setXLT(_data.timeMulti)
            break;
        }
      });
      console.log(a);
      setData(a)
    }
    ),
    []
  );

  const [value, setValue] = React.useState(null);
  const [value2, setValue2] = React.useState(null);

  return (<Box sx={{ flexGrow: 1 }}>
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Tester le calcul d'itinéraire</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Entrez deux coordonnées GPS pour tester le calcul d'itinéraire entre deux points
        </DialogContentText>
        <Grid direction={"row"} container spacing={2}>
          <Grid item xs={12}>
            <div style={{ paddingTop: 20 }}>
              <GooglePlacesAutocomplete
                selectProps={{
                  value,
                  onChange: setValue,
                  placeholder: "Départ"
                }}
                apiKey="AIzaSyAij5xMD1mt2CWq5_NNLkTBMEUK1_AS6N8"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div style={{ paddingTop: 20, paddingBottom: 20 }}>
              <GooglePlacesAutocomplete

                selectProps={{
                  value2,
                  onChange: setValue2,
                  placeholder: "Arrivée"
                }}
                apiKey="AIzaSyAij5xMD1mt2CWq5_NNLkTBMEUK1_AS6N8"
              />
            </div>
          </Grid>

        
          <Grid item xs={12}>
            <Item >{respS}</Item>
          </Grid>
          <Grid item xs={12}>
            <Item >{respM}</Item>
          </Grid>
          <Grid item xs={12}>
            <Item >{respL}</Item>
          </Grid>
          <Grid item xs={12}>
            <Item >{respXL}</Item>
          </Grid>

        </Grid>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Fermer</Button>
        <Button onClick={handleCloseOK}>Simuler</Button>
      </DialogActions>
    </Dialog>
    <Dialog open={openUpdate} onClose={handleCloseUpdate}>
      <DialogTitle>Attention - Tentative de mise à jour</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Vous êtes sur le point de changer le calcul des prix des courses. Tout changement est définitif.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseUpdate}>Annuler</Button>
        <Button onClick={handleCloseUpdateOk}>Valider</Button>
      </DialogActions>
    </Dialog>
    <Grid direction={"row"} container spacing={2}>
      <Grid item xs={3}>
        <Item style={section}>Taille</Item>
      </Grid>
      <Grid item xs={3}>
        <Item style={section}>Base</Item>
      </Grid>
      <Grid item xs={3}>
        <Item style={section}>Multiplicateur - Distance</Item>
      </Grid>
      <Grid item xs={3}>
        <Item style={section}>Multiplicateur - Temps</Item>
      </Grid>
      <Grid item xs={3}>
        <Item style={section}>S</Item>
      </Grid>
      <Grid item xs={3}>
        <Item style={section}>
          <TextField
            type="number"
            id="SB"
            fullWidth
            value={SB}
            onChange={changeSB}
          />
        </Item>
      </Grid>
      <Grid item xs={3}>
        <Item style={section}>
          <TextField
            type="number"
            id="SD"
            fullWidth
            value={SD}
            onChange={changeSD}
          />
        </Item>
      </Grid>
      <Grid item xs={3}>
        <Item style={section}>
          <TextField
            type="number"
            id="ST"
            fullWidth
            value={ST}
            onChange={changeST}
          />
        </Item>
      </Grid>
      <Grid item xs={3}>
        <Item style={section}>M</Item>
      </Grid>
      <Grid item xs={3}>
        <Item style={section}>
          <TextField
            type="number"
            id="MB"
            fullWidth
            value={MB}
            onChange={changeMB}
          />
        </Item>
      </Grid>
      <Grid item xs={3}>
        <Item style={section}>
          <TextField
            type="number"
            id="MD"
            fullWidth
            value={MD}
            onChange={changeMD}
          />
        </Item>
      </Grid>
      <Grid item xs={3}>
        <Item style={section}>
          <TextField
            type="number"
            id="MT"
            fullWidth
            value={MT}
            onChange={changeMT}
          />
        </Item>
      </Grid>
      <Grid item xs={3}>
        <Item style={section}>L</Item>
      </Grid>
      <Grid item xs={3}>
        <Item style={section}>
          <TextField
            id="LB"
            type="number"
            fullWidth
            value={LB}
            onChange={changeLB}
          />
        </Item>
      </Grid>
      <Grid item xs={3}>
        <Item style={section}>
          <TextField
            id="LD"
            type="number"
            fullWidth
            value={LD}
            onChange={changeLD}
          /></Item>
      </Grid>
      <Grid item xs={3}>
        <Item style={section}>
          <TextField
            id="LT"
            type="number"
            fullWidth
            value={LT}
            onChange={changeLT}
          />
        </Item>
      </Grid>
      <Grid item xs={3}>
        <Item style={section}>XL</Item>
      </Grid>
      <Grid item xs={3}>
        <Item style={section}>
          <TextField
            id="XLB"
            type="number"
            fullWidth
            value={XLB}
            onChange={changeXLB}
          />
        </Item>
      </Grid>
      <Grid item xs={3}>
        <Item style={section}>
          <TextField
            id="XLD"
            type="number"
            fullWidth
            value={XLD}
            onChange={changeXLD}
          />
        </Item>
      </Grid>
      <Grid item xs={3}>
        <Item style={section}>
          <TextField
            id="XLT"
            type="number"
            fullWidth
            value={XLT}
            onChange={changeXLT}
          />
        </Item>
      </Grid>
      <Grid item xs={12}>

      </Grid>
      <Grid item xs={4}>
        <Button variant="contained" color="primary" onClick={updateData} >Mettre à Jour</Button>
      </Grid>
      <Grid item xs={4}>
        <Button variant="contained" color="primary" onClick={handleClickOpen()}>Tester</Button>
      </Grid>
    </Grid>

  </Box>
  );
}
