import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Container from "@material-ui/core/Container";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Visibility from "@material-ui/icons/Visibility";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import { httpsCallable } from 'firebase/functions';
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { onSnapshot, collection, where, getDoc, doc } from "firebase/firestore";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Slider from "@material-ui/core/Slider";

import logo from "assets/img/anonymous.png";

import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Collapse from "@material-ui/core/Collapse";

import { db, storage, functions, auth } from "firebase.jsx"; // course
import { ref, getDownloadURL } from "firebase/storage";

import CommandeModal from "components/Fiches/Commande.jsx";



const useStyles = makeStyles(styles);
export default function UserModal(props) {
    const classes = useStyles();
    const { userData } = props;

    const [urlImg, setUrlImg] = React.useState("anonymous");

    const [orderLive, setOrderLive] = React.useState(false);
    const [spent, setSpent] = React.useState(0);
    const [orderDone, setOrderDone] = React.useState(false);
    const [ticketLive, setTicketLive] = React.useState(false);
    const [ticketDone, setTicketDone] = React.useState(false);


    useEffect(() =>
        onSnapshot(collection(db, `stripe_customers/${userData.id}/payments`), (snapshot) => {
            let _spent = 0
            snapshot.forEach((doc) => {
                const _data = doc.data();
                if (_data.status == "succeeded") {
                    _spent += parseInt(_data.amount_received)
                }
                // fr.push({ ..._data, id: doc.id });
            });
            setSpent(((_spent) / 100))
        }
        ),
        []
    );

    useEffect(async () => {
        let _store = ref(storage, `users/${userData.id}`)
        try {
            const url = await getDownloadURL(_store)
            setUrlImg(url)
        } catch (error) {
            setUrlImg("anonymous")
        }
    });

    const [openUserModal, setOpenUserModal] = React.useState(false);
    const [dataUserModal, setDataUserModal] = React.useState({});
    const test = async (id) => {

        const orderRef = doc(db, "orders", id);
        const orderSnap = await getDoc(orderRef)
        const _orderData = orderSnap.data()
        const orderData = { ..._orderData, id: id }

        const userRef = doc(db, "users", orderData.customerID);
        const userSnap = await getDoc(userRef);
        const userData = userSnap.data()

        const chaliarRef = doc(db, "users", orderData.deliveryID);
        const chaliarSnap = await getDoc(chaliarRef);
        const chaliarData = chaliarSnap.data()

        const fullCommandData = {
            order: orderData,
            orderChaliar: { ...chaliarData, id: orderData.deliveryID },
            orderUser: { ...userData, id: orderData.customerID }
        }

        setDataUserModal(fullCommandData)
        setOpenUserModal(true)
    };
    const handleCloseUserModal = () => {
        setDataUserModal({})
        setOpenUserModal(false);
    };
    return (
        <div>
            {(openUserModal) ?

                <Dialog
                    fullWidth
                    open={openUserModal}
                    onClose={handleCloseUserModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {dataUserModal.order.id}
                    </DialogTitle>
                    <DialogContent>
                        <CommandeModal userData={dataUserModal}></CommandeModal>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseUserModal}>Fermer</Button>
                    </DialogActions>
                </Dialog> : null
            }

            <Grid container spacing={2} direction="column"

            >
                <Grid item xs={12} >
                    <div className={classes.logoImage}>
                        <img src={(urlImg != "anonymous") ? urlImg : logo} alt="logo" className={classes.img} />
                    </div>
                </Grid>
                <Grid item xs={12} >
                    <Card>
                        <CardHeader
                            title="INFORMATIONS"
                        >
                        </CardHeader>
                        <CardContent>
                            <p className={classes.testLeft}>Total des Commandes</p>
                            <p className={classes.testRight}>{spent.toFixed(2)} €</p>
                            <p className={classes.testLeft}>Adresse</p>
                            <p className={classes.testRight}>{userData.adress}</p>
                            <p className={classes.testRight}>{userData.zipCode}, {userData.city}</p>
                            <p className={classes.testLeft}>Prénom</p>
                            <p className={classes.testRight}>{userData.firstName}</p>
                            <p className={classes.testLeft}>Nom</p>
                            <p className={classes.testRight}>{userData.lastName}</p>
                            <p className={classes.testLeft}>Téléphone</p>
                            <p className={classes.testRight}>{userData.phone}</p>
                            <p className={classes.testLeft}>E-mail</p>
                            <p className={classes.testRight}>{userData.email}</p>
                            <p className={classes.testLeft}>Course(s) en recherche</p>
                            <p className={classes.testRight}>{userData.open.length}</p>
                            <p className={classes.testLeft}>Course(s) en cours</p>
                            <p className={classes.testRight}>{userData.inprogress.length}</p>
                            <p className={classes.testLeft}>Course(s) à noter</p>
                            <p className={classes.testRight}>{userData.notation.length}</p>
                            <p className={classes.testLeft}>Course(s) terminée(s)</p>
                            <p className={classes.testRight}>{userData.done.length}</p>
                            <p className={classes.testLeft}>Signalement(s) en cours</p>
                            <p className={classes.testRight}>0</p>
                            <p className={classes.testLeft}>Signalement(s) en fini(s)</p>
                            <p className={classes.testRight}>0</p>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} >
                    <Card>
                        <CardHeader
                            title="Courses en cours"
                            action={
                                <IconButton
                                    onClick={() => setOrderLive(!orderLive)}
                                    aria-label="expand"
                                >
                                    {orderLive ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            }
                        >
                        </CardHeader>
                        <div>
                            <Collapse in={orderLive} timeout="auto" unmountOnExit>
                                {
                                    (userData.open.concat(userData.inprogress).length > 0) ? <CardContent>
                                        {userData.open.concat(userData.inprogress).map((data, index) => {
                                            console.log("data", data)
                                            return (
                                                <CardHeader
                                                    key={index}
                                                    title={data.id} onClick={() => test(data.id)}
                                                    action={
                                                        <IconButton
                                                            aria-label="expand"
                                                        >
                                                            <KeyboardArrowRight />
                                                        </IconButton>
                                                    }
                                                >
                                                </CardHeader>
                                            )
                                        })}
                                    </CardContent> : null
                                }

                            </Collapse>
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            title="Courses terminées"
                            action={
                                <IconButton
                                    onClick={() => setOrderDone(!orderDone)}
                                    aria-label="expand"
                                >
                                    {orderDone ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            }
                        >
                        </CardHeader>
                        <div>
                            <Collapse in={orderDone} timeout="auto" unmountOnExit>
                                {
                                    (userData.notation.concat(userData.done).length > 0) ? <CardContent>
                                        {userData.notation.concat(userData.done).map((data, index) => {
                                            console.log("data", data)
                                            return (
                                                <CardHeader
                                                    key={index}
                                                    title={data.id} onClick={() => test(data.id)}
                                                    action={
                                                        <IconButton
                                                            aria-label="expand"
                                                        >
                                                            <KeyboardArrowRight />
                                                        </IconButton>
                                                    }
                                                >
                                                </CardHeader>
                                            )
                                        })}
                                    </CardContent> : null
                                }

                            </Collapse>
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            title="Signalements en cours"
                            action={
                                <IconButton
                                    onClick={() => setTicketLive(!ticketLive)}
                                    aria-label="expand"
                                >
                                    {ticketLive ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            }
                        >
                        </CardHeader>
                        <div>
                            <Collapse in={ticketLive} timeout="auto" unmountOnExit>
                            </Collapse>
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            title="Signalements terminés"
                            action={
                                <IconButton
                                    onClick={() => setTicketDone(!ticketDone)}
                                    aria-label="expand"
                                >
                                    {ticketDone ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            }
                        >
                        </CardHeader>
                        <div>
                            <Collapse in={ticketDone} timeout="auto" unmountOnExit>
                            </Collapse>
                        </div>
                    </Card>
                </Grid>
            </Grid>
        </div >
    );
}


UserModal.propTypes = {
    userData: PropTypes.any,
};
