import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ArrowForward from "@material-ui/icons/ArrowForward";
import { db, storage, functions } from "firebase.jsx"; // add
import { httpsCallable } from 'firebase/functions';
import { onSnapshot, collection, where, getDoc, doc } from "firebase/firestore";
import ChaliarModal from "components/Fiches/Chaliar.jsx";

// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";


import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles(styles);

export default function PayOutTable(props) {
    const classes = useStyles();
    const { tableHead, tableData, tableHeaderColor, tableType } = props;


    const [openUserModal, setOpenUserModal] = React.useState(false);
    const [dataUserModal, setDataUserModal] = React.useState({});

    const test = async (data) => {
        console.log("ID TEST AHA", data);
        const docRef = doc(db, "users", data.id_stripe_chaliar);
        const docSnap = await getDoc(docRef);
        const _data = docSnap.data()
        console.log("DATA DATA", _data);
        setDataUserModal({ ..._data, id: data.id_stripe_chaliar })
        setOpenUserModal(true)
    };

    const handleCloseUserModal = () => {
        setOpenUserModal(false);
    };

    return (
        <div className={classes.tableResponsive}>

            <Dialog
                fullWidth
                open={openUserModal}
                onClose={handleCloseUserModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Chaliar
                </DialogTitle>
                <DialogContent>
                    <ChaliarModal userData={dataUserModal}></ChaliarModal>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUserModal}>Fermer</Button>
                </DialogActions>
            </Dialog>


            <Table className={classes.table}>
                {tableHead !== undefined ? (
                    <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                        <TableRow className={classes.tableHeadRow}>
                            {tableHead.map((prop, key) => {
                                return (
                                    <TableCell
                                        className={classes.tableCell + " " + classes.tableHeadCell}
                                        key={key}
                                    >
                                        {prop}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                ) : null}
                <TableBody>
                    {tableData.map((data, index) => {
                        return (<TableRow key={index} className={classes.tableBodyRow}>
                            {
                                Object.keys(data).map(function (pro) {
                                    console.log(tableData)
                                    if (pro == "id_stripe_chaliar") {
                                        return (<TableCell key={pro} className={classes.tableCellClick} onClick={() => test(data)}>
                                            {data[pro]}
                                        </TableCell>)
                                    }
                                })
                            }
                            {
                                Object.keys(data).map(function (pro) {
                                    if (pro == "id_transfert") {
                                        return (<TableCell key={pro} className={classes.tableCell}>
                                            {data[pro]}
                                        </TableCell>)
                                    }
                                })
                            }
                            {
                                Object.keys(data).map(function (pro) {
                                    if (pro == "date") {
                                        return (<TableCell key={pro} className={classes.tableCell}>
                                            {new Date(data[pro].seconds * 1000).toDateString()}
                                        </TableCell>)
                                    }
                                })
                            }

                            {
                                Object.keys(data).map(function (pro) {
                                    if (pro == "value") {
                                        return (<TableCell key={pro} className={classes.tableCell}>
                                            {data[pro] / 100} €
                                        </TableCell>)
                                    }
                                })
                            }

                        </TableRow>)
                    })}

                </TableBody>
            </Table>
        </div>
    );
}

PayOutTable.defaultProps = {
    tableHeaderColor: "gray",
};

PayOutTable.propTypes = {
    tableHeaderColor: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info",
        "rose",
        "gray",
    ]),
    tableHead: PropTypes.arrayOf(PropTypes.string),
    tableData: PropTypes.any,
    tableType: PropTypes.string,
};
