import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import History from "components/Table/History.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import PayOutTable from "components/Table/Pay.jsx";
import CardBody from "components/Card/CardBody.jsx";

import Button from '@material-ui/core/Button';
import { onSnapshot, collection, where } from "firebase/firestore";
import { db } from "firebase.jsx";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function PayOut() {
  const [demandes, setDemandes] = useState([]);
  const [history, setHistory] = useState([]);




  const classes = useStyles();

  useEffect(() =>
    onSnapshot(collection(db, "payouts"), (snapshot) => {
      let data = []
      snapshot.forEach((doc) => {
        const _data = doc.data();
        data.push({ ..._data, id: doc.id });
        // postData.push({ ...doc.data(), id: doc.id });
      });
      setHistory(data);
      console.log(data)
    }
    ),
    []
  );


  useEffect(() =>
    onSnapshot(collection(db, "stripe_chaliar"), (snapshot) => {
      let data = []
      snapshot.forEach((doc) => {
        const _data = doc.data();
        console.log(_data.requested > 0)
        if (_data.requested > 0) {
          data.push({ ..._data, id: doc.id });
        }
        // postData.push({ ...doc.data(), id: doc.id });
      });
      setDemandes(data);
      console.log(data)
    }
    ),
    []
  );
  const notify = () => toast.error('Fond Stripe Insuffisant', {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  const notify2 = () => toast.error('Compte Stripe non Validé', {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });



  return (
    <GridContainer>
      <ToastContainer
        position="bottom-right"
        autoClose={false}
        hideProgressBar={true}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />



      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Demandes de virements</h4>
            <p className={classes.cardCategoryWhite}>
              Demandes en attente de validation
            </p>
          </CardHeader>
          <CardBody>
            <PayOutTable
              tableType="live"
              tableHeaderColor="warning"
              tableHead={[
                "ID Chaliar",
                "Montant",
                "Actions",
              ]}
              tableData={demandes}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="success">
            <h4 className={classes.cardTitleWhite}>Historique</h4>
            <p className={classes.cardCategoryWhite}>Virements effectués</p>
          </CardHeader>
          <CardBody>
            <History
              tableHeaderColor="success"
              tableHead={[
                "ID Chaliar",
                "ID transfert",
                "Date",
                "Montant",]}
              tableData={history}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
