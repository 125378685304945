import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Scheduled from "components/Table/Commandes/Scheduled";
import { onSnapshot, collection, where, addDoc } from "firebase/firestore";
import { db } from "firebase.jsx"; // add
import Faq_FR from "components/Table/Faq_fr";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function FaQ() {

  const [french, setFrench] = useState([]);

  useEffect(() =>
    onSnapshot(collection(db, "faq"), (snapshot) => {
      const fr = [];
      snapshot.forEach((doc) => {
        const _data = doc.data();
        fr.push({ ..._data, id: doc.id });
      });
      setFrench(fr)
    }
    ),
    []
  );


  const [lang, setLang] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseOk = () => {
    console.log("lang", lang)
    console.log("q", q)
    console.log("a", a)
    addDoc(collection(db, "faq"), {
      language: lang,
      question: q,
      reponse: a
    });
    setOpen(false);
  };

  const [q, setQ] = useState();
  const [a, setA] = useState();
  const questionChange = (event) => {
    setQ(event.target.value)
  };
  const answerChange = (event) => {
    setA(event.target.value)
  };


  const handleChangeLang = (event) => {
    setLang(event.target.value)
  };

  const classes = useStyles();
  return (
    <GridContainer>

      <GridItem xs={12} sm={12} md={12}>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Nouvelle Question-Reponse</DialogTitle>
          <DialogContent>
            <DialogContentText>
              La question et la reponse seront affichés pour les applications. Validez le formulaire après avoir remplis les deux champs
            </DialogContentText>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Langue</InputLabel>
              <Select
              defaultValue=''
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={lang}
                label="Langue"
                onChange={handleChangeLang}
              >
                <MenuItem value="french">Français</MenuItem>
                <MenuItem value="english">Anglais</MenuItem>
              </Select>
            </FormControl>
            <TextField
              onChange={questionChange}
              margin="dense"
              id="question"
              label="Question"
              type="email"
              fullWidth
              variant="standard"
            />
            <TextField
              onChange={answerChange}
              margin="dense"
              id="answer"
              label="Reponse"
              type="email"
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            <Button onClick={handleCloseOk}>Valider</Button>
          </DialogActions>
        </Dialog>
        <Button variant="contained" color="primary" onClick={handleClickOpen}>Nouvelle Question</Button>

        <Card>
          <CardHeader color="success">
            <h4 className={classes.cardTitleWhite}>F.A.Q.</h4>
            <p className={classes.cardCategoryWhite}>Français</p>
          </CardHeader>
          <CardBody>
            <Faq_FR
              tableType="live"
              tableHeaderColor="warning"
              tableHead={[
                "Question",
                "Reponse",
                "Langue",
                "Action",
              ]}
              tableData={french}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
