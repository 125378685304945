import {
    warningColor,
    primaryColor,
    dangerColor,
    successColor,
    infoColor,
    roseColor,
    grayColor,
    defaultFont,
} from "assets/jss/material-dashboard-react.js";

const tableStyle = (theme) => ({
    thaiTextFieldInputProps: {
        paddingTop: '16px'
    },
    warningTableHeader: {
        color: warningColor[0],
    },
    primaryTableHeader: {
        color: primaryColor[0],
    },
    dangerTableHeader: {
        color: dangerColor[0],
    },
    successTableHeader: {
        color: successColor[0],
    },
    infoTableHeader: {
        color: infoColor[0],
    },
    roseTableHeader: {
        color: roseColor[0],
    },
    grayTableHeader: {
        color: grayColor[0],
    },
    table: {
        marginBottom: "0",
        width: "100%",
        maxWidth: "100%",
        backgroundColor: "transparent",
        borderSpacing: "0",
        borderCollapse: "collapse",
    },
    tableHeadCell: {
        color: "inherit",
        ...defaultFont,
        "&, &$tableCell": {
            fontSize: "1em",
        },
    },
    tableCell: {
        ...defaultFont,
        lineHeight: "1.42857143",
        padding: "12px 8px",
        verticalAlign: "middle",
        fontSize: "0.8125rem",
    },
    tableCellClick: {
        ...defaultFont,
        color: "rgba(0, 0, 255, 0.5)",
        cursor: "pointer",
        lineHeight: "1.42857143",
        padding: "12px 8px",
        verticalAlign: "middle",
        fontSize: "0.8125rem",
    },
    tableResponsive: {
        width: "100%",
        marginTop: theme.spacing(3),
        overflowX: "auto",
    },
    tableHeadRow: {
        height: "56px",
        color: "inherit",
        display: "table-row",
        outline: "none",
        verticalAlign: "middle",
    },
    tableBodyRow: {
        height: "48px",
        color: "inherit",
        display: "table-row",
        outline: "none",
        verticalAlign: "middle",
    },
    tableBodyRowClick: {
        height: "48px",
        cursor: "pointer",
        color: "inherit",
        display: "table-row",
        outline: "none",
        verticalAlign: "middle",
    },
    logoImage: {
        transform: "translate(-50%, 0)",
        position: "relative",
        left: "50%",
        maxHeight: "250px",
        display: "inline-block",
    },
    img: {
        borderRadius: "50%",
        maxWidth: "100%",
        maxHeight: "250px",
        position: "relative",
        verticalAlign: "middle",
        border: "0",
    },
    imgRec: {
        // borderRadius: "50%",
        maxWidth: "100%",
        maxHeight: "250px",
        position: "relative",
        verticalAlign: "middle",
        border: "0",
    },
    testCenter: {
        textAlign: "center",
        fontWeight: "700",
    },
    testLeft: {
        margin: "0",
        margin: "0",
        fontWeight: "475",
        textAlign: "left"
    },
    testRight: {
        margin: "0",
        padding: "0",
        textAlign: "right"
    }
    , testRightClick: {
        margin: "0",
        color: "rgba(0, 0, 255, 0.5)",
        cursor: "pointer",
        padding: "0",
        textAlign: "right"

    }
});

export default tableStyle;