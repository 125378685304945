import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Visibility from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor } = props;
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
            return (
              <TableRow key={key} className={classes.tableBodyRow}>
                {prop.map((prop, key) => {
                  if (prop === "action") {
                    return (
                      <TableCell className={classes.tableCell} key={key}>
                        <Tooltip
                          id="tooltip-top"
                          title="Suivre"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <IconButton
                            aria-label="Suivre"
                            className={classes.tableActionButton}
                          >
                            <ArrowForward
                              className={
                                classes.tableActionButtonIcon +
                                " " +
                                classes.arrowForward
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    );
                  } else if (prop === "view") {
                    return (
                      <TableCell className={classes.tableCell} key={key}>
                        <Tooltip
                          id="tooltip-top"
                          title="Voir"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <IconButton
                            aria-label="Voir les documents"
                            className={classes.tableActionButton}
                          >
                            <Visibility
                              className={
                                classes.tableActionButtonIcon +
                                " " +
                                classes.arrowForward
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    );
                  } else if (prop === "val") {
                    return (
                      <TableCell className={classes.tableCell} key={key}>
                        <Tooltip
                          id="tooltip-top"
                          title="Accepter"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <IconButton
                            aria-label="Accepter"
                            className={classes.tableActionButton}
                          >
                            <Check
                              className={
                                classes.tableActionButtonIcon +
                                " " +
                                classes.arrowForward
                              }
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          id="tooltip-top"
                          title="Refuser"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <IconButton
                            aria-label="Refuser"
                            className={classes.tableActionButton}
                          >
                            <Close
                              className={
                                classes.tableActionButtonIcon +
                                " " +
                                classes.arrowForward
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell className={classes.tableCell} key={key}>
                        {prop}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
