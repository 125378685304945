import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import User from "components/Table/User";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import * as qs from 'qs'
import { onSnapshot, collection, where } from "firebase/firestore";
import { db } from "firebase.jsx"; // add
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useLocation } from 'react-router-dom';
// import { useSearchParams } from "react-router-dom";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function Users(props) {
  const location = useLocation();
  const [str, setStr] = useState((qs.parse(location.search, { ignoreQueryPrefix: true }).id) ? qs.parse(location.search, { ignoreQueryPrefix: true }).id : "");

  const [live, setLive] = useState([]);
  const [display, setDisplay] = useState([]);

  useEffect(() =>
    onSnapshot(collection(db, "users"), (snapshot) => {
      let _id = qs.parse(location.search, { ignoreQueryPrefix: true }).id
      const d = [];
      const c = [];
      snapshot.forEach((doc) => {
        const _data = doc.data();
        if (_data.isChaliar == false) {
          d.push({ ..._data, id: doc.id });
        }
        if (_id != null) {
          if (doc.id == _id) {
            c.push({ ..._data, id: doc.id });
          }
        }
      });
      setLive(d);
      if (_id != null) {
        setDisplay(c);
      } else {
        setDisplay(d);
      }

    }
    ),
    []
  );


  const handleChange = (event) => {
    const _data = [];
    live.forEach((doc) => {
      if (JSON.stringify(doc).includes(event.target.value)) {
        _data.push(doc)
      }
    });
    setStr(event.target.value)
    setDisplay(_data)
  };

  const classes = useStyles();

  const exportData = (event, param) => {
    console.log(display)
    // const rows = [
    //   ["First Name", "Last Name", "Address", "zipCode", "City", "Phone Number", "Money Earned", "Finished Rides", "Upcoming Rides", "Ongoing Rides"],
    // ];

    let csvContent = "data:text/csv;charset=utf-8,First Name;Last Name;Address;Zip Code;City;Email;Phone;Finished Rides;Upcoming Rides;Ongoing Rides;Device Token\r\n";
    let tmp = display
    tmp.forEach(function (rowArray) {
      let row = `${rowArray.firstName};${rowArray.lastName};${rowArray.adress};${rowArray.zipCode};${rowArray.city};${rowArray.email};${rowArray.phone};${rowArray.done.length.toString()};${rowArray.scheduled.length.toString()};${rowArray.inprogress.length.toString()};${rowArray.deviceToken}`
      csvContent += row + "\r\n";
      // ${(parseInt(rowArray.CA) * 0.9).toString()};${rowArray.done.length.toString()};${rowArray.scheduled.length.toString()};${rowArray.inprogress.length.toString()}
    });
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "users.csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <TextField value={str} id="demo-helper-text-misaligned-no-helper" label="Rechercher" onChange={handleChange} fullWidth />
        <Card>
          <CardHeader color="success">
            <h4 className={classes.cardTitleWhite}>Utilisateurs</h4>
            <p className={classes.cardCategoryWhite}>Liste des utilisateurs de l'application</p>
          </CardHeader>
          <CardBody>
            <User
              tableType="live"
              tableHeaderColor="warning"
              tableHead={[
                "ID",
                "Prénom",
                "Nom",
                "Téléphone",
                "Email",
                "Adresse",
                "Courses",
                "Type",
                "Action",
              ]}
              tableData={display}
            />
          </CardBody>
        </Card>
        <Button variant="contained" color="primary" onClick={exportData}>Export</Button>
      </GridItem>
    </GridContainer>
  );
}
